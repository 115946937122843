/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import banner1 from '../../assets/images/event-banner.png';
import banner2 from '../../assets/images/partners-banner.png';
import thumbnail2 from '../../assets/images/event-thumbnail2.png';
import thumbnail3 from '../../assets/images/event-thumbnail3.png';
import location from '../../assets/images/icons/location-2.svg';
import calendar from '../../assets/images/icons/calendar-2.svg';
import Card from '../../components/Card';
import img1 from '../../assets/images/card.png';
import img2 from '../../assets/images/card2.png';
import EventsApiEndpoints from '../../iticket-sdk/services/events.api';
import MapComponent from '../../components/mapComponent';

function EventDatils() {
  const { i18n, t } = useTranslation();

  const { eventId } = useParams();
  const [videoLink, setVideoLink] = useState();
  const [gallery, setGallery] = useState([]);

  const { data, isLoading, error } = useQuery(
    ['event-details'],
    () => EventsApiEndpoints.eventDetails(eventId),
    {
      enabled: !!eventId,
      select: (data) => {
        return data.data;
      },
    },
  );
  console.log(data);

  useEffect(() => {
    const linkUrl = data?.youtube?.video_link;
    const urlSplitted = linkUrl?.split('=');
    urlSplitted && setVideoLink(`https://www.youtube.com/embed/${urlSplitted[1]}`);

    // set Gallery
    if (data?.gallery?.length > 0) {
      setGallery(data?.gallery.map((img) => (
        {
          original: img?.url,
          thumbnail: img?.url,
        }
      )));
    }
  }, [data]);

  // https://www.youtube.com/watch?v=W9ufeRcNJ-M
  const card1 = {
    img: img1,
    date: '29 October',
    title: 'David Lee Garza with Ramon Ayala',
    location: 'Space, riyadh',
    price: '375 SR',
    category: 'concert',
  };
  const card2 = {
    img: img2,
    date: '29 Novmber',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '400 SR',
    category: 'comedy',
  };
  return (
    <div className="event-details_page">
      <div className="image-slider_container">
        <ImageGallery showFullscreenButton={false} showPlayButton={false} items={gallery} />
        <div className="content">
          <div className="chip py-1 px-4 font-weight-semiBold mb-3">{data?.category?.title}</div>
          <h4 className="title">
            {data?.title}
          </h4>
        </div>

      </div>
      {/* details */}
      <div className="event-details py-2 py-md-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 my-3 order-2 order-lg-1 ">
              <img className="img-fluid main-img mb-3" src={data?.poster?.url} alt="eventDetails" />
              <div className="row align-items-center">
                <div className="col-md-6 my-2 order-1 order-md-1">
                  <ul className="list-unstyled">
                    <li className="mb-3 d-flex align-items-center">
                      <span className="icon">
                        <img src={location} alt="location" />
                      </span>
                      <span className="text location font-weight-regular mx-2">
                        {data?.locations?.address}
                      </span>
                    </li>
                    <li className="mb-3 d-flex align-items-center">
                      <span className="icon">
                        <img src={calendar} alt="calendar" />
                      </span>
                      <span className="text font-weight-regular mx-2">
                        {data?.days?.[0]?.date}
                        {' '}
                        -
                        {' '}
                        {data?.days?.[0]?.start_time}
                      </span>
                    </li>
                    {/* time */}
                    {/* <li className="mb-3 d-flex align-items-center">
                      <span className="icon">
                        <img src={clock} alt="clock" />
                      </span>
                      <span className="text font-weight-regular mx-2">
                        Starts 07:00 PM
                      </span>
                    </li> */}
                    {/* <li className="mb-3 d-flex align-items-center">
                      <span className="icon">
                        <img src={location2} alt="location" />
                      </span>
                      <span className="text font-weight-regular mx-2">
                        Space, 52 king fahd st, riyadh
                      </span>
                    </li> */}
                  </ul>
                </div>
                {/* image */}
                <div className="col-md-6 order-3 order-md-2 d-flex justify-content-center justify-content-md-end my-2">
                  <img src={data?.logo?.url} alt="" width={231} />
                </div>
                <div className="col-12 order-2 order-md-3">
                  <p className="text description font-weight-light my-2">
                    {data?.description}
                  </p>
                </div>
              </div>
              <hr className="my-4" />
              {/* Rules */}
              <h6 className="title text-uppercase font-weight-semiBold my-4">Rules</h6>
              {/* <ul className="rules">
                <li className="text description font-weight-light mb-2">
                  The toppings you may choour face tose for that TV dise for that TV dinner.
                </li>
                <li className="text description font-weight-light mb-2">
                  Shoour face tose for that TV dip for foods, the paint you may slap on your face tose for that.
                </li>
                <li className="text description font-weight-light mb-2">
                  The toppings you may choour face tose for that TV dise for that TV dinner.
                </li>
                <li className="text description font-weight-light mb-2">
                  Face tose for that TV dise for that TV dinner pizza slice when you forgot to shoour face tose for that TV dip for foods, the paint you may slap on your face tose for that.
                </li>
              </ul> */}
              <div
                dangerouslySetInnerHTML={{ __html: data?.instructions }}
              />
              <hr className="my-4" />
              {/* Promo Video */}
              <h6 className="title text-uppercase font-weight-semiBold my-4">Promo Video</h6>
              <div className="video-container">
                {/* <video src={video} type="video/mp4" className="w-100" controls /> */}
                <iframe
                  width="100%"
                  height="300"
                  src={videoLink}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              </div>

              <hr className="my-4" />
              {/* sponsers */}
              <h6 className="title text-uppercase font-weight-semiBold my-4">sponsers</h6>
              <div className="sponsers d-flex justify-content-between my-3">
                {data?.sponsors?.map((sponsor) => (
                  <img id={sponsor?.url} src={sponsor?.url} alt={sponsor?.name} className="img-fluid" />
                ))}
              </div>

              <hr className="my-4" />
              {/* location */}
              <h6 className="title text-uppercase font-weight-semiBold my-4">location</h6>
              <div className="map my-3">
              {/* <iframe 
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3624.258621377211!2d46.669999999999995!3d24.718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQzJzA0LjgiTiA0NsKwNDAnMTIuMCJF!5e0!3m2!1sar!2seg!4v1679516575848!5m2!1sar!2seg"
               width="600" height="450"  allowfullscreen=""
               loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* {data?.locations?.lat &&
                } */}
                <MapComponent lat={+data?.locations?.lat} lng={+data?.locations?.long} />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 my-3 order-1 order-lg-2">
              {/* Ticket prices */}
              <div className="ticket-prices p-3 p-md-4">
                <h6 className="title text-uppercase font-weight-semiBold">Tickets Prices</h6>
                <ul className="list-unstyled text-uppercase">
                  {data?.tickets?.map((ticket) => (
                    <li className="d-flex justify-content-between py-3 py-md-4">
                      <span>
                        {ticket?.type}
                        <div className="text">
                          {ticket?.desceription}
                        </div>
                      </span>
                      <span className="price font-weight-medium">
                        {ticket?.price}
                      </span>
                    </li>
                  ))}
                  {/* <li className="d-flex justify-content-between py-3 py-md-4">
                    <span>
                      Gold
                      <div className="text">
                        Integer id enim feugiat et laoreet elementum rhoncus nibh massa.
                      </div>
                    </span>
                    <span className="price font-weight-medium">
                      650 SR
                    </span>
                  </li> */}

                </ul>
                <div className="d-flex justify-content-center mt-3">
                  <Link className="main-button primary lg text-uppercase w-100" to={`/events/book-ticket/${eventId}`}> book a ticket</Link>
                </div>
              </div>
              {/* Need Help */}
              <div className="need-help p-3 p-md-4 my-3 d-none d-lg-block">
                <h5 className="title font-weight-semiBold">
                  Need Help?
                </h5>
                <div className="">
                  <Link
                    className="main-button primary lg text-uppercase w-100"
                    to="/support"
                  >
                    Contact support
                  </Link>
                </div>
              </div>

              {/* Top Events */}
              <div className="top-events p-3 p-md-4 my-3 d-none d-lg-block">
                <div className="row">
                  <div className="col-12">
                    {data?.latest_events?.map((card) => (
                      <>
                        <Card key={card?.id} card={card} lang={i18n.language} />
                        <br />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDatils;
