/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Autoplay, Pagination, EffectFade,
} from 'swiper';
import {
  Link,
} from 'react-router-dom';
import { createSelector } from 'reselect';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { DateObject } from 'react-multi-date-picker';
import Card from '../../components/Card';
import EventCard from '../../components/EventCard';
import arrowLeft from '../../assets/images/arrow-left.svg';
import IconSlider from './IconSlider';
import MultiDatepicker from '../../controls/MultiDatepicker';
import SearchDropdown from '../../controls/SearchDropdown';
import logo from '../../assets/images/logo.svg';
import pepsiLogo from '../../assets/images/pepsi-logo.svg';
import paypalLogo from '../../assets/images/paypal-logo.svg';
import GoogleLogo from '../../assets/images/Google-logo.svg';
import airbnbLogo from '../../assets/images/airbnb-logo.svg';

import img1 from '../../assets/images/card.png';
import img2 from '../../assets/images/card2.png';
import img3 from '../../assets/images/card3.png';
import img4 from '../../assets/images/card4.png';

import art from '../../assets/images/events/art.png';
import discoBall from '../../assets/images/events/disco-ball.png';
import cinema from '../../assets/images/events/cinema.png';
import mic from '../../assets/images/events/mic.png';
import movie from '../../assets/images/events/movie.png';
import pen from '../../assets/images/events/pen.png';
import podium from '../../assets/images/events/podium.png';
import ticket from '../../assets/images/events/ticket.png';
import test from '../../assets/images/events/test.png';
import Loading from '../../components/Loading';
import HomeApiEndpoints from '../../iticket-sdk/services/home.api';
import EventsApiEndpoints from '../../iticket-sdk/services/events.api';

const eventsArr = [
  {
    img: img1,
    date: '29 October',
    title: 'David Lee Garza with Ramon Ayala',
    location: 'Space, riyadh',
    price: '375 SR',
    category: 'concert',
  },
  {
    img: img2,
    date: '29 Novmber',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '400 SR',
    category: 'comedy',
  },
  {
    img: img4,
    date: '29 October',
    title: 'David Lee Garza with Ramon Ayala',
    location: 'Space, riyadh',
    price: '350 SR',
    category: 'concert',
  },
  {
    img: img3,
    date: '20 Novmber',
    title: 'David Lee Garza ',
    location: 'Space, riyadh',
    price: '150 SR',
    category: 'art',
  },
  {
    img: img4,
    date: '20 February',
    title: 'David Lee Garza ',
    location: 'Space, riyadh',
    price: '240 SR',
    category: 'science',
  },
  {
    img: img2,
    date: '05 October',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '500 SR',
    category: 'design',
  },
  {
    img: img1,
    date: '15 October',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '50 SR',
    category: 'acting',
  },
  {
    img: img4,
    date: '15 October',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '150 SR',
    category: 'dance',
  },
  {
    img: img4,
    date: '20 February',
    title: 'David Lee Garza ',
    location: 'Space, riyadh',
    price: '240 SR',
    category: 'science',
  },
  {
    img: img2,
    date: '05 October',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '500 SR',
    category: 'design',
  },
  {
    img: img1,
    date: '20 February',
    title: 'David Lee Garza ',
    location: 'Space, riyadh',
    price: '240 SR',
    category: 'conference',
  },
  {
    img: img4,
    date: '05 October',
    title: 'Manuel De La Mare jam',
    location: 'Space, riyadh',
    price: '500 SR',
    category: 'conference',
  },
];
function Home() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [eventsToShow, setEventsToShow] = useState([]);
  const [dateValues, setDateValues] = useState([]);
  const [params, setParams] = useState({
    paginate: 8,
    category_id: null,
  });

  const { data: categories, isLoading } = useQuery(
    ['events-categories', i18n.language],
    HomeApiEndpoints.getAllCategories,
    {
      staleTime: 30000,
      refetchOnMount: false,
      select: (data) => {
        return data?.data;
      },
    },
  );

  const {
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
    hasNextPage,
    data: events,
    fetchNextPage,
    isFetchingNextPage,
    error: eventsError,
  } = useInfiniteQuery(['events', { ...params }, i18n.language], () => EventsApiEndpoints.getEvents(params), {
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.data?.pagination?.meta?.page?.next;
    },
  });

  const { loading, error } = useSelector(
    createSelector(
      (state) => state.home,
      (homeReducer) => ({
        loading: homeReducer?.loading,
        error: homeReducer?.error,
      }),
    ),
  );

  const eventParams = {
    slidesPerView: 4,
    spaceBetween: 10,
    watchOverflow: 'true',
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.4,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2.4,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2.9,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  };
  const options = [
    { value: 'riyadh', label: 'Riyadh' },
    { value: 'jeddah', label: 'Jeddah' },
    { value: 'makkah', label: 'Makkah' },
    { value: 'madinah', label: 'Madinah' },
    { value: 'khobar', label: 'Khobar' },
    { value: 'tabuk', label: 'Tabuk' },
    { value: 'taif', label: 'Taif' },
    { value: 'al-kharj', label: 'Al-Kharj' },
    { value: 'dhahran', label: 'Dhahran' },
  ];

  const iconsData = [
    { id: 'all', img: ticket, text: 'all' },
    { id: 'concert', img: mic, text: 'CONCERT' },
    { id: 'comedy', img: cinema, text: 'COMEDY' },
    { id: 'art', img: art, text: 'ART' },
    { id: 'science', img: test, text: 'SCIENCE' },
    { id: 'design', img: pen, text: 'DESIGN' },
    { id: 'acting', img: movie, text: 'ACTING' },
    { id: 'dance', img: discoBall, text: 'DANCE' },
    { id: 'conference', img: podium, text: 'Conference' },
  ];
  const iconsParams = {
    slidesPerView: 9,
    spaceBetween: 10,
    // watchOverflow: 'true',
    // slidesPerColumn: '2',
    // loop: true,
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false,
    // },
    watchOverflow: true,
    modules: [Pagination, Navigation],
    breakpoints: {
      320: {
        slidesPerView: 4.5,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 4.5,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 7,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 7,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 9,
        spaceBetween: 10,
      },
    },
  };

  function onCloseDateFilter() {
    setParams({
      ...params,
      date_from: `${dateValues[0]?.day}-${dateValues[0]?.month.number}-${dateValues[0]?.year}`,
      date_to: `${dateValues[1]?.day}-${dateValues[1]?.month.number}-${dateValues[1]?.year}`,
    });
  }

  return (
    <>
      {loading && <Loading />}
      <Container className="mt-4 pt-4 upcoming-events-container">
        <div className="d-flex align-items-center justify-content-between">
          <p className="dark-gray-color mb-1">UPCOMING EVENTS</p>
          <Link className="main-color d-block d-md-none" to="/">
            {' '}
            View ALL
            {' '}
            <span className="iticket-icon-arrow-down arrow-right d-inline-block copyright-font" />
            {' '}
          </Link>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-start align-items-lg-center  flex-column flex-lg-row">
            <h3 className="my-2 my-md-0">Riyadh, SA</h3>
            <div className="d-flex align-items-center flex-column flex-lg-row">
              <div className="mx-0 mx-lg-3 w-100 select-location-dropdown my-2 my-lg-0">
                <SearchDropdown options={options} placeholder="Change Location" />
              </div>
              <div className="my-2 my-lg-0">
                <MultiDatepicker values={dateValues} setValues={setDateValues} handleAfterClose={onCloseDateFilter} />
              </div>
            </div>
          </div>
          <Link className="main-color d-none d-md-flex align-items-center" to="/">
            {' '}
            <span className="font-weight-semiBold mx-1">View ALL</span>
            {' '}
            <span className="iticket-icon-arrow-down arrow-right d-inline-block copyright-font" />
            {' '}
          </Link>
        </div>
        <div className="icon-slider-parent-container my-2 my-md-5 pb-3">
          {/* <IconSlider /> */}
          <Swiper className="w-100" {...iconsParams}>
            <SwiperSlide
              onClick={() => setParams({
                ...params,
                category_id: null,
              })}
              className="slider-slide"
            >
              <div className={`icon-slider-container ${params?.category_id === null ? 'active' : ''} py-2 text-center`}>
                <img alt="ticket" className="mb-1" src={ticket} />
                <h6 className="mb-0 mt-2">all</h6>
              </div>
            </SwiperSlide>

            {categories?.map((cate) => (
              <SwiperSlide
                key={cate.id}
                onClick={() => setParams({
                  ...params,
                  category_id: cate.id,
                })}
                className="slider-slide"
              >
                <div className={`icon-slider-container ${params?.category_id === cate.id ? 'active' : ''} py-2 text-center`}>
                  <img className="mb-1" src={cate?.icon?.url} alt={cate?.icon?.name} />
                  <h6 className="mb-0 mt-2">{cate.title}</h6>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Row className="mb-5 mt-4 mt-lg-2">

          {
            events?.pages?.map((group, i) => {
              return (
                <React.Fragment key={i}>
                  {
                    group?.data?.map((card) => (
                      <Col lg="4" md="6" className="my-3 my-md-4">
                        <Card card={card} lang={i18n.language} />
                      </Col>
                    ))
                  }
                </React.Fragment>
              );
            })
          }

        </Row>
      </Container>

      {/* //////////////////////////// PREVIOUS Events */}
      <div className="previous-events-container py-3">
        <div className="container">
          <div className="relative">
            <h3 className="text-white py-3 pb-md-5"> PREVIOUS Events </h3>
          </div>
          <div className="swiper-container">
            <div className="w-100 swiper events-swiper-container">
              <Swiper className="w-100" {...eventParams}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((image) => (
                  <SwiperSlide className="slider-slide">
                    <EventCard />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-button-next"><img className="img-fluid" src={arrowLeft} alt="" /></div>
              <div className="swiper-button-prev"><img className="img-fluid" src={arrowLeft} alt="" /></div>
            </div>
          </div>
        </div>
      </div>

      {/* Partners sec */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="partners-container p-3 p-md-4 mb-4">
              <div className="content text-center d-flex justify-content-center align-items-center flex-column px-4">
                <img src={logo} alt="iTicket logo" className="logo img-fluid mb-2 mb-md-3" />
                <h5 className="title font-weight-regular">
                  we are your partners when it comes to fInd events!
                </h5>
              </div>
              <div className="partners-imgs px-2 px-md-4">
                <h5 className="partners-title text-center m-0 font-weight-semiBold  py-3 py-md-4">
                  TRUSTED BY
                </h5>
                <div className="row justify-content-center pb-2 pb-md-4">
                  <div className="col-lg-3 col-md-4 col-6 my-2 d-flex">
                    <img src={pepsiLogo} alt="pepsiLogo" className="img-fluid w-75 m-auto" />
                  </div>
                  <div className="col-lg-3 col-md-4 col-6 my-2 d-flex">
                    <img src={airbnbLogo} alt="airbnbLogo" className="img-fluid w-75 m-auto" />
                  </div>
                  <div className="col-lg-3 col-md-4 col-6 my-2 d-flex">
                    <img src={GoogleLogo} alt="GoogleLogo" className="img-fluid w-75 m-auto" />
                  </div>
                  <div className="col-lg-3 col-md-4 col-6 my-2 d-flex">
                    <img src={paypalLogo} alt="paypalLogo" className="img-fluid w-75 m-auto" />
                  </div>
                </div>
                <div className="d-flex justify-content-center my-2 my-lg-3">
                  <Link className="main-button primary lg text-uppercase" to="/upcoming-events">Discover EVENTS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
