import React, { useState, useCallback } from 'react';
import {
  Label, Form, Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import schema from './schema';
import InputField from '../../../components/input';
import { setServerErrors } from '../../../helpers';
import { login } from '../../../iticket-sdk/redux/actions/auth.actions';
import Loading from '../../../components/Loading';

function Login({
  isModalOpen, toggleModal, toggleForgetPassModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    setLoading(true);
    const { userAgent } = window.navigator;
    dispatch(login(data)).then((res) => {
      setLoading(false);
      toggleModal();
    }).catch((error) => {
      setLoading(false);
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else if (error?.response?.data?.code === 417) {
        const responseErrors = {
          email_or_phone: {
            field: 'email_or_phone',
            message: error?.response?.data?.data,
          },
        };
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  };
  const hanleOpenForgetPass = useCallback(() => {
    toggleModal();
    toggleForgetPassModal(true);
  }, []);
  return (
    <Modal className="auth-modal mt-4 mt-md-0" isOpen={isModalOpen} toggle={() => toggleModal()} centered>
      <div className="close-icon cursor-pointer" onClick={() => toggleModal()}>
        {' '}
        <span className="iticket-icon-close" />
        {' '}
      </div>
      <ModalBody className="px-lg-5">
        <h3 className="font-weight-semiBold mb-4">Login to your account!</h3>
        <div className="buttons-container d-flex justify-content-between py-2 px-3 px-md-5">
          <button type="button" className="btn d-flex align-items-center">
            {' '}
            <span className="iticket-icon-google px-2" />
            {' '}
            Google
          </button>
          <button type="button" className="btn d-flex align-items-center">
            <span className="iticket-icon-apple px-2" />
            {' '}
            Apple
          </button>
        </div>
        <Form className=" mt-4 px-3 px-md-5" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                placeholder="Email or Phone number"
                name="email"
                errors={errors}
                type="text"
                {...register('email_or_phone')}
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder="password"
                name="password"
                errors={errors}
                type={showPass ? 'text' : 'password'}
                {...register('password')}
                wrapperClassName="ltr-input"
              />
              <span onClick={() => setShowPass(!showPass)} className="cursor-pointer show-pass iticket-icon-eye" />
            </Col>
            <Col md={12}>
              <button type="submit" className="main-button primary btn d-block w-100"> login</button>
            </Col>
          </Row>
        </Form>
        <p onClick={hanleOpenForgetPass} className="mt-4 text-center cursor-pointer">Forgot Password?</p>
      </ModalBody>
      {loading
      && <Loading /> }
    </Modal>
  );
}

export default Login;
