/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Label, Form, Row, Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSelector } from 'reselect';
import toast from 'react-hot-toast';
import schema from './schema';
import InputField from '../../components/input';
import TelephoneInput from '../../components/phone-input';
import SettingBinaryInput from '../../components/binary-input/setting-input';
import { setServerErrors } from '../../helpers';
import walletIcon from '../../assets/images/wallet-white.svg';
import userIcon from '../../assets/images/icons/user-octagon.svg';
import galleryExportIcon from '../../assets/images/icons/gallery-export.svg';
import language from '../../assets/images/setting-icons/language.svg';
import login from '../../assets/images/setting-icons/login.svg';
import notification from '../../assets/images/setting-icons/notification.svg';
import verified from '../../assets/images/setting-icons/verified.svg';
import verifymobile from '../../assets/images/setting-icons/verify-mobile.svg';
import verifymail from '../../assets/images/setting-icons/verify-mail.svg';
import avatar from '../../assets/images/avatar.jpeg';
import VerifyPopup from './verifyPopup';
import UpdateData from './updateData';
import OTP from './otp';
import {
  updateUserProfile, getLanguage, profileVerifications,
} from '../../iticket-sdk/redux/actions/user.actions';
import Loading from '../../components/Loading';

function Settings() {
  const { data } = useSelector(
    createSelector(
      (state) => state.user,
      (userReducer) => ({
        data: userReducer,
      }),
    ),
  );
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [showPopup, setShowPopUp] = useState(false);
  const [showOtpPopup, setShowOtpPopUp] = useState(false);
  const [showUpdatePopup, setShowUpdatePopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState('en');
  const dispatch = useDispatch();

  const {
    reset, register, handleSubmit, control, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const handleUploadImage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setProfileImg(e.target.files[0]);
    reader.onloadend = () => {
      setProfilePhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    reset(data?.user);
    if (data?.user?.settings?.language?.id == 2) {
      setLang('en');
    } else {
      setLang('ar');
    }
  }, [data]);
  const onSubmit = (data) => {
    setLoading(true);
    const languageId = lang == 'en' ? 2 : 1;
    const formData = new FormData();
    formData.append('_method', 'patch');
    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('phone', data?.phone);
    if (profileImg) {
      formData.append('image', profileImg);
    }
    if (data?.notify_with_phone == 'true' || data?.notify_with_phone == true) {
      formData.append('notify_with_phone', true);
    } else {
      formData.append('notify_with_phone', false);
    }
    if (data?.notify_with_email == 'true' || data?.notify_with_email == true) {
      formData.append('notify_with_email', true);
    } else {
      formData.append('notify_with_email', false);
    }
    formData.append('language_id', languageId);

    dispatch(updateUserProfile(formData)).then((res) => {
      setShowUpdatePopUp(true);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  };

  /// verify mail
  const handleVerify = (mail) => {
    const formData = new FormData();
    formData.append('type', mail);
    dispatch(profileVerifications(formData)).then((res) => {
      if (mail === 'email') {
        setShowPopUp(true);
      }
      if (mail === 'phone') {
        setShowOtpPopUp(true);
      }
      console.log(res);
    }).catch((error) => {
      toast.error('Something went wrong');
    });
  };
  return (
    <div className="settings-page">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-6 my-2">
            <Link className="main-button secondary lg text-uppercase mb-3" to="/">
              <span className="mx-2">
                <span className="iticket-icon-arrow-down arrow-left d-inline-block" />
              </span>
              <span className="mx-2">
                Go home
              </span>
            </Link>
            {/* BreadCrumb */}
            <div className="breadcrumb d-flex align-items-center">
              <Link to="/">
                Home
              </Link>
              <span className="icon mx-2 mx-lg-3">
                <span className="iticket-icon-arrow-down arrow-right d-inline-block" />
              </span>
              <span className="current">
                Settings
              </span>
            </div>
          </div>
        </div>

        <div className="settings-header d-flex align-items-center justify-content-between pb-3">
          <h1 className="title text-uppercase font-weight-semiBold mb-0">
            Settings
          </h1>
          <div className="wallet-box d-flex align-items-center">
            <div className="img d-flex align-items-center justify-content-center">
              <img src={walletIcon} alt="wallet Icon" />
            </div>
            <div className="">
              <h5 className="wallet-title text-uppercase font-weight-semiBold mb-1">
                Wallet Balance
              </h5>
              <h6 className="price text-uppercase font-weight-semiBold m-0">
                SAR
                {' '}
                {data?.user?.wallet?.balance}
              </h6>
            </div>
          </div>
        </div>

        {/* settings content */}
        <div className="settings-content pt-4 pt-md-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-xl-5">
              <div className="col-lg-6">
                <h6 className="settings-title font-weight-medium mb-2 mb-md-3">
                  <span className="icon">
                    <img src={userIcon} className="img-fluid" alt="user Icon" />
                  </span>
                  {' '}
                  Personal Information
                </h6>
                <Row className="mt-5">
                  {/* Personal Information */}
                  <div className="col-lg-5">
                    <div className="personal-info">
                      <div className="user-img d-flex flex-row flex-md-column align-items-center mb-3 mb-md-0">
                        <div className="user-avatar mb-0 mb-md-3 rounded-circle d-flex justify-content-center align-items-center">
                          {profilePhoto
                            ? <img src={profilePhoto} alt="User" className="rounded-circle" />
                            : (
                              <img src={data?.user?.profile_image?.path || avatar} className="rounded-circle" alt="user avatar" />
                            )}
                        </div>

                        <div className="img-input d-flex align-items-center">
                          <input type="file" accept=".png, .jpg, .jpeg" onChange={handleUploadImage} />
                          <span>
                            <img src={galleryExportIcon} className="img-fluid" alt="gallery Export Icon" />
                          </span>
                          <span className="text font-weight-medium">
                            Upload new avatar
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <Row>
                      <Col md={12}>
                        <Label>
                          Full Name
                        </Label>
                        <InputField
                          placeholder="Full Name"
                          name="name"
                          errors={errors}
                          type="text"
                          {...register('name')}
                        />
                      </Col>
                      <Col md={12}>
                        <div className="position-relative presonal-info_input">
                          <Label>
                            Email
                          </Label>
                          <InputField
                            placeholder="Email"
                            name="email"
                            errors={errors}
                            type="email"
                            {...register('email')}
                          />
                          {data?.user?.is_email_verified
                            ? (
                              <div className="d-flex verified-container font-weight-semiBold">
                                <img src={verified} alt="verified" />
                                <span>Verified</span>
                              </div>
                            )
                            : (
                              <div onClick={() => handleVerify('email')} className="main-button primary d-flex unverified-container font-weight-semiBold cursor-pointer">
                                <img src={verifymail} alt="verified" />
                                <span>Verify</span>
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="position-relative presonal-info_input">
                          <Label>
                            Mobile Number
                          </Label>
                          <TelephoneInput
                            name="Mobile Number"
                            control={control}
                            errors={errors}
                            {...register('phone')}
                            theme="light"
                            wrapperClassName="mb-1 w-100"
                          />
                          {data?.user?.is_phone_verified
                            ? (
                              <div className="d-flex verified-container font-weight-semiBold">
                                <img src={verified} alt="verified" />
                                <span>Verified</span>
                              </div>
                            )
                            : (
                              <div onClick={() => handleVerify('phone')} className="main-button primary d-flex unverified-container font-weight-semiBold cursor-pointer">
                                <img src={verifymobile} alt="verified" />
                                <span>Verify</span>
                              </div>
                            ) }

                        </div>

                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>

              <div className="col-lg-3 border-container">
                <h6 className="settings-title font-weight-medium mb-2 mb-md-3">
                  <span className="icon">
                    <img src={login} className="img-fluid" alt="user Icon" />
                  </span>
                  {' '}
                  Login & Security
                </h6>
                {/* //// pass info */}
                <div className="d-flex justify-content-between pass-info my-4 my-md-5">
                  <div className="">
                    <p>Password</p>
                    <span>updated 1 month ago.</span>
                  </div>
                  <Link to="/reset-password" className="change-pass"> Change </Link>
                </div>
                <hr />
                <div className="lang-container my-4 my-md-5">
                  <h6 className="settings-title font-weight-medium mb-2 mb-md-3">
                    <span className="icon">
                      <img src={language} className="img-fluid" alt="user Icon" />
                    </span>
                    {' '}
                    Language
                  </h6>
                  <div className="mt-4 pass-info">
                    <p>Language</p>
                    <div className="d-flex justify-content-between">
                      <button onClick={() => setLang('en')} type="button" className={`${lang === 'en' ? 'main-button primary w-40' : 'main-button secondary border-0 w-40'}`}> English </button>
                      <button onClick={() => setLang('ar')} type="button" className={`${lang === 'ar' ? 'main-button primary w-40' : 'main-button secondary border-0 w-40'}`}> العربيه </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 border-container">
                <h6 className="settings-title font-weight-medium mb-2 mb-md-3">
                  <span className="icon">
                    <img src={notification} className="img-fluid" alt="user Icon" />
                  </span>
                  {' '}
                  Notification & Emails
                </h6>
                {/* Notification & Emails */}
                <div className="pass-info my-4 my-md-5">
                  <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
                    <div className="">
                      <p>SMS Notifications</p>
                      <span>Turned on by default.</span>
                    </div>
                    <SettingBinaryInput
                      errors={errors}
                      // label="ee"
                      className="align-items-center d-flex flex-column"
                      type="switch"
                      value
                      name="send_sms"
                      {...register('notify_with_phone')}
                      // wrapperClassName="setting-switch-button"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <p>Email Notifications</p>
                      <span>Email Notifications</span>
                    </div>
                    <SettingBinaryInput
                      className="align-items-center d-flex flex-column"
                      errors={errors}
                      // label="eee"
                      type="switch"
                      value
                      name="send_emails"
                      {...register('notify_with_email')}
                    />
                  </div>
                </div>

              </div>
              <div className="col-lg-6 mt-5">
                <Row>
                  <div className="col-lg-5" />
                  <div className="col-lg-7">
                    <button type="submit" className="main-button primary w-100 d-block py-3">Save Changes</button>
                  </div>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {loading
      && <Loading /> }
      <VerifyPopup showPopup={showPopup} setShowPopUp={setShowPopUp} />
      <UpdateData showUpdatePopup={showUpdatePopup} setShowUpdatePopUp={setShowUpdatePopUp} text=" Data Updated Successfully" />
      <OTP showOtpPopup={showOtpPopup} setShowOtpPopUp={setShowOtpPopUp} />

    </div>
  );
}

export default Settings;
