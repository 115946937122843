import React from 'react';
import { Label, FormFeedback } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { getErrorsByPath } from '../../helpers';
import 'react-phone-input-2/lib/style.css';

const TelephoneInput = React.forwardRef(({
  underlined, theme, wrapperClassName, ...props
}, ref) => {
  React.useImperativeHandle(ref, () => ({}));

  return (
    <div className={`${underlined ? 'underlined-input' : 'outlined-input'} ${theme === 'light' ? 'light' : ''} ${wrapperClassName || ''}`}>
      <Controller
        control={props?.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            prefix="+"
            inputClass={`form-control ${getErrorsByPath(props.errors, props.name) ? 'is-invalid' : ''}`}
            value={value}
            inputProps={{
              onChange,
            }}
            country="sa"
            countryCodeEditable={false}
            onlyCountries={['sa']}
            disableDropdown
          />
        )}
      />
      {props.label && <Label for={props.id}>{props.label}</Label>}
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </div>
  );
});

export default TelephoneInput;
