/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const createBooking = (data) => {
  return (dispatch) => {
    return Api.booking.createBooking(data).then((response) => {
      dispatch({
        type: 'CREATE_BOOKING',
        data: response,
      });
      return response;
    });
  };
};
export const saveBooking = (data) => {
  return (dispatch) => {
    return Api.booking.saveBooking(bookingId, data).then((response) => {
      dispatch({
        type: 'SAVE_BOOKING',
        data: response,
      });
      return response;
    });
  };
};
