/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Container, Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import OtpInput from '../../../components/otp-input';
import schema from './schema';
import {
  verifyPhoneNumber,
} from '../../../iticket-sdk/redux/actions/user.actions';
import Loading from '../../../components/Loading';
import { setServerErrors } from '../../../helpers';

function OTP({
  showOtpPopup, setShowOtpPopUp, otpId,
}) {
  const dispatch = useDispatch();
  const [isPhoneNumberModalOpen, togglePhoneNumberModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    control, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const closeOtpModal = useCallback(() => {
    setShowOtpPopUp(false);
    togglePhoneNumberModal(!isPhoneNumberModalOpen);
  }, []);

  const onSubmit = useCallback((data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('type', 'phone');
    formData.append('otp', data.otp);
    dispatch(verifyPhoneNumber(formData)).then((res) => {
      closeOtpModal();
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else if (error?.response?.data?.code === 417) {
        const responseErrors = {
          otp: {
            field: 'otp',
            message: error?.response?.data?.data,
          },
        };
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  }, []);

  return (
    <>
      <Modal className="auth-modal" isOpen={showOtpPopup} toggle={() => setShowOtpPopUp()} centered>
        <div className="close-icon cursor-pointer" onClick={closeOtpModal}>
          {' '}
          <span className="iticket-icon-close" />
          {' '}
        </div>
        <ModalBody>
          <Container>
            <Row className="mt-2">
              <Col md="12" className="d-flex flex-column align-items-center justify-content-center">
                <h3 className="font-weight-bold mb-3">Mobile Verification</h3>
                <p className="text-center mb-3 sign-up-text">
                  We will send you an OTP to your mobile number, Please enter the code.
                  {/* {phoneNumber} */}
                </p>
              </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-center">
                  <OtpInput
                    name="otp"
                    control={control}
                    errors={errors}
                    {...register('otp')}
                  />
                </Col>
                <Col md="12">
                  <button type="submit" className="d-block w-100 primary main-button">Verify</button>
                </Col>
              </Row>
            </form>
          </Container>
        </ModalBody>
      </Modal>
      {loading
      && <Loading /> }
    </>
  );
}

export default OTP;
