import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  InputGroup, InputGroupText, Modal, ModalBody,
} from 'reactstrap';

import ticketImg from '../../assets/images/event-banner.png';
import calendarIcon from '../../assets/images/icons/calendar-2.svg';
import locationIcon from '../../assets/images/icons/location-3.svg';
import doneIcon from '../../assets/images/tick-circle.svg';

function MyTickets() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundDone, setRefundDone] = useState(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  const { status } = useParams();
  console.log(status);
  return (
    <div className="my-tickets_page">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-6 order-1 my-3">
            <Link className="main-button secondary lg text-uppercase" to="/">
              <span className="mx-2">
                <span className="iticket-icon-arrow-down arrow-left d-inline-block" />
              </span>
              <span className="mx-2">
                Go home
              </span>
            </Link>
          </div>
          <div className="col-md-6 order-3 order-md-2 d-flex justify-content-end my-3">
            <InputGroup className="banner-search-input">
              <InputGroupText>
                <span className="iticket-icon-search" />
              </InputGroupText>
              <input className="px-2" placeholder="Search events..." type="search" />
            </InputGroup>
          </div>
          <div className="col-md-6 order-2 order-md-3 my-3">
            <div className="my-tickets_header">
              <h6 className="subtitle font-weight-medium">
                You booked 15 Tickets
              </h6>
              <h1 className="title text-uppercase font-weight-semiBold mb-0">
                My Tickets
              </h1>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-start justify-content-md-end order-4 my-3">
            <div className="tickets-tabs d-flex ">
              <div className={`${status === 'all' ? 'active' : ' ' } tab font-weight-semiBold `}>
                {' '}
                <Link to="/my-tickets/all"> All tickets</Link>
              </div>
              <div className={`${status === 'upcoming' ? 'active' : ' ' } tab font-weight-semiBold `}>
                {' '}
                <Link to="/my-tickets/upcoming"> Upcoming</Link>
              </div>
              <div className={`${status === 'previous' ? 'active' : ' ' } tab font-weight-semiBold `}>
                {' '}
                <Link to="/my-tickets/previous"> Previous</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {[1, 2, 3, 4].map((i) => (
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="ticket-card">
                <div className="img-container">
                  <img src={ticketImg} className="img-fluid w-100" alt="" />
                  <div className="type upcoming font-weight-semiBold text-uppercase">
                    UPCOMING
                  </div>
                </div>
                <div className="ticket-card_content p-3">
                  <h5 className="title font-weight-semiBold text-uppercase ">
                    DJ David Tort saturday night live JAM
                  </h5>
                  <ul className="list-unstyled mt-3">
                    <li className="d-flex align-items-center mb-2">
                      <span className="icon">
                        <img src={locationIcon} alt="location" />
                      </span>
                      <span className="text">
                        Space, 52 king fahd st, riyadh
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <span className="icon">
                        <img src={calendarIcon} alt="calendar " />
                      </span>
                      <span className="text">
                        15 November 2021  -  07:00 PM
                      </span>
                    </li>
                  </ul>
                  <div className="total d-flex justify-content-between py-3">
                    <span className="font-weight-medium">Total</span>
                    <span className="font-weight-semiBold">2,230 SR</span>
                  </div>

                  <button type="button" onClick={toggleModal} className="main-button secondary lg text-uppercase p-0 w-100">
                    Refund Ticket
                  </button>

                </div>
              </div>
            </div>
          ))}
          {[1, 2, 3, 4].map((i) => (
            <div className="col-lg-3 col-sm-6 my-3">
              <div className="ticket-card">
                <div className="img-container">
                  <img src={ticketImg} className="img-fluid w-100" alt="" />
                  <div className="type previous font-weight-semiBold text-uppercase">
                    UPCOMING
                  </div>
                </div>
                <div className="ticket-card_content p-3">
                  <h5 className="title font-weight-semiBold text-uppercase ">
                    DJ David Tort saturday night live JAM
                  </h5>
                  <ul className="list-unstyled mt-3">
                    <li className="d-flex align-items-center mb-2">
                      <span className="icon">
                        <img src={locationIcon} alt="location" />
                      </span>
                      <span className="text">
                        Space, 52 king fahd st, riyadh
                      </span>
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <span className="icon">
                        <img src={calendarIcon} alt="calendar " />
                      </span>
                      <span className="text">
                        15 November 2021  -  07:00 PM
                      </span>
                    </li>
                  </ul>
                  <div className="total d-flex justify-content-between py-3">
                    <span className="font-weight-medium">Total</span>
                    <span className="font-weight-semiBold">2,230 SR</span>
                  </div>

                  <button type="button" onClick={toggleModal} className="main-button secondary lg text-uppercase p-0 w-100">
                    Refund Ticket
                  </button>

                </div>
              </div>
            </div>
          ))}
        </div>

        {/* see more */}
        <div className="show-more d-flex justify-content-center d-md-none my-3">
          <button type="button" className="main-button secondary lg text-uppercase px-4">
            See more
          </button>
        </div>

        <Modal className="auth-modal refund-modal mt-4 mt-md-0" isOpen={isModalOpen} toggle={() => toggleModal()} centered>
          <div className="close-icon cursor-pointer" onClick={() => toggleModal()}>
            {' '}
            <span className="iticket-icon-close" />
            {' '}
          </div>
          <ModalBody className="px-lg-5 px-4 pb-4">
            {!refundDone && (
              <>
                <h3 className="title font-weight-semiBold mb-4">
                  Are you sure you want to refund this ticket?
                </h3>
                <div className="row ">
                  <div className="col-6 my-2">
                    <button type="button" onClick={toggleModal} className="main-button secondary  text-uppercase px-0 w-100">
                      Cancel
                    </button>
                  </div>
                  <div className="col-6 my-2">
                    <button type="button" onClick={() => setRefundDone(true)} className="main-button primary text-uppercase px-0 w-100">
                      Yes, Refund
                    </button>
                  </div>
                </div>
              </>
            )}

            {refundDone && (
              <div className="done-success text-center">
                <img src={doneIcon} alt="success" />
                <p className="text my-3">
                  Ticket refund request successfully sent!
                </p>
              </div>
            )}

          </ModalBody>
        </Modal>

      </div>
    </div>
  );
}

export default MyTickets;
