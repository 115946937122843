import React from 'react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import BasicFooter from '../components/layout/footer/BasicFooter';

function PublicWhiteLayout({ secret, basicFooter }) {
  const auth = localStorage.getItem('auth') === 'true';
  if (!auth && secret) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <div className="white-header">
        <Header />
      </div>
      <Outlet />
      {basicFooter ? <BasicFooter /> : <Footer />}

    </>
  );
}

export default PublicWhiteLayout;
