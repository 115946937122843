import Api from '../../services';

export const getUser = () => {
  return (dispatch) => {
    return Api.user.getUser().then((response) => {
      dispatch({
        type: 'GET_USER',
        data: response,
      });
      return response;
    });
  };
};

export const updateUserProfile = (data, params) => {
  return (dispatch) => {
    return Api.user.updateUserProfile(data, params).then((response) => {
      dispatch(getUser());
      return response;
    });
  };
};
export const getLanguage = () => {
  return (dispatch) => {
    return Api.user.getLanguage().then((response) => {
      dispatch({
        type: 'GET_LANGUAGE',
        data: response,
      });
      return response;
    });
  };
};

export const profileVerifications = (data) => {
  return (dispatch) => {
    return Api.user.profileVerifications(data).then((response) => {
      dispatch({
        type: 'VERIFY_PROFILE_NUMBER',
        data: response,
      });
      return response;
    });
  };
};
export const verifyPhoneNumber = (data) => {
  return (dispatch) => {
    return Api.user.verifyPhoneNumber(data).then((response) => {
      console.log(response.data.user);
      dispatch({
        type: 'OTP_VERIFIED',
        data: response.data.user,
      });
      return response;
    });
  };
};
