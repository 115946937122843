import React, { useState, useCallback } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSelector } from 'reselect';
import { toast } from 'react-hot-toast';
import schema from './schema';
import InputField from '../../../components/input';
import { setServerErrors } from '../../../helpers';
import { changePassword } from '../../../iticket-sdk/redux/actions/auth.actions';
import Loading from '../../../components/Loading';
import UpdateData from '../../settings/updateData';

function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpdatePopup, setShowUpdatePopUp] = useState(false);

  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const { userData } = useSelector(
    createSelector(
      (state) => state.user,
      (userReducer) => ({
        userData: userReducer.user,
      }),
    ),
  );
  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('_method', 'patch');
    formData.append('current_password', data?.current_password);
    formData.append('new_password', data?.new_password);
    formData.append('new_password_confirmation', data.new_password_confirmation);
    dispatch(changePassword(formData)).then((res) => {
      setLoading(false);
      setShowUpdatePopUp(true);
      reset();
    }).catch((error) => {
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  };

  return (
    <div className="container">
      <div className="padding-top auth-modal reset-pass-container">
        <h3 className="font-weight-semiBold mb-4">Reset Password</h3>
        <p className="text-center mb-3 sign-up-text">Please enter your new password!</p>

        <Form className=" mt-4 px-3 px-md-5" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                placeholder="Old Password"
                name="current_password"
                errors={errors}
                type="password"
                {...register('current_password')}
              />
            </Col>
            <Col md={12}>
              <InputField
                placeholder="New Password"
                name="new_password"
                errors={errors}
                type="password"
                {...register('new_password')}
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder="Confirm Password"
                name="new_password_confirmation"
                errors={errors}
                type="password"
                {...register('new_password_confirmation')}
                wrapperClassName=""
              />
            </Col>
            <Col md={12}>
              <button type="submit" className="main-button primary btn d-block w-100"> Reset Password</button>
            </Col>
          </Row>
        </Form>
      </div>
      <UpdateData showUpdatePopup={showUpdatePopup} setShowUpdatePopUp={setShowUpdatePopUp} text="Password Updated Successfully" />
      {loading
      && <Loading /> }
    </div>
  );
}

export default ResetPassword;
