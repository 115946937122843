/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Shimmer } from 'react-shimmer';
import { useQuery } from 'react-query';
import HomeApiEndpoints from '../../iticket-sdk/services/home.api';

function DynamicPage() {
  const { pageSlug, pageId } = useParams();
  const { i18n, t } = useTranslation();

  if (!pageSlug || !pageId) return;

  // get Page
  const { data: page, isLoading } = useQuery(
    ['page', i18n.language, pageId],
    () => HomeApiEndpoints.getPage(pageId),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data.data;
      },
    },
  );

  return (
    <div className="dynamic-page padding-top mt-4 mt-md-5 pb-4 pb-md-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {isLoading && [1, 2, 3, 4, 5, 6, 7].map((index) => (
              <Shimmer key={index} className="w-100 mb-3" height={7} width={100} />
            ))}

            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: page?.content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicPage;
