/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useMemo, useCallback,
  useRef,
} from 'react';
import {
  Link, NavLink,
  useNavigate,
} from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink as HeaderNavLink,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../../../assets/images/logo.svg';
import logoDark from '../../../assets/images/logo-dark.svg';
import avatar from '../../../assets/images/avatar.jpeg';
import ticketIcon from '../../../assets/images/ticket-2.svg';
import cardIcon from '../../../assets/images/card-pos.svg';
import settingIcon from '../../../assets/images/setting-2.svg';
import EN from '../../../assets/images/en.svg';
import AR from '../../../assets/images/ar.svg';
import localStorageProvider from '../../../localStorageProvider';
import Login from '../../../views/auth/login';
import SignUp from '../../../views/auth/SignUp';
import { getUser } from '../../../iticket-sdk/redux/actions/user.actions';
import { checkAuth, logout } from '../../../iticket-sdk/redux/actions/auth.actions';

function Header() {
  const { i18n, t } = useTranslation();
  const [currentLang, setCurrentLang] = useState('en');
  const [colorChange, setColorchange] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [isLoginModalOpen, toggleLoginModal] = useState(false);
  const [isLSignUpModalOpen, toggleSignUpModal] = useState(false);
  const [openForgetPassModal, toggleForgetPassModal] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const profileRef = useRef();
  const notificationRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(checkAuth()).then((response) => {
      if (response) {
        dispatch(getUser()).then((response) => {
          localStorage.setItem('userID', response.data.id);
        });
      }
    });
  }, []);
  const changeLanguage = useCallback((lng) => {
    i18n.changeLanguage(lng);
    document.querySelector('html').dir = i18n.dir();
    document.querySelector('html').lang = lng;
    document.querySelector('#bootstrap-ltr').disabled = lng === 'ar';
    document.querySelector('#bootstrap-rtl').disabled = lng === 'en';
    localStorageProvider.set('locale', lng);
    if (lng === 'en') {
      setCurrentLang('en');
    }
    if (lng === 'ar') {
      setCurrentLang('ar');
    }
  }, []);
  const changeNavbarColor = useCallback(() => {
    if (window.scrollY >= 60) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  }, []);
  /// change languages
  useEffect(() => {
    if (localStorage.getItem('locale') === 'ar') {
      setCurrentLang('ar');
    }
    if (localStorage.getItem('locale') === 'en') {
      setCurrentLang('en');
    } else {
      setCurrentLang('ar');
    }
    //
    window.addEventListener('scroll', changeNavbarColor);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showProfileDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotificationDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showNotificationDropdown]);
  /// signout
  const handleSignOut = () => {
    navigate('/');
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    dispatch(logout()).then((res) => {
    });
    setShowProfileDropdown(false);
  };
  return (
    <>
      <header className={`px-md-5 py-0 justify-content-between ${colorChange ? 'navScroll' : ''}`}>
        <Navbar dark expand="md" className="px-md-5 justify-content-between">
          <div onClick={() => setShowNav(!showNav)} className={`menuBtn ${showNav && 'closeMenu'}`}>
            <div className="btnLine" />
            <div className="btnLine" />
            <div className="btnLine" />
          </div>

          <ul className="list-unstyled lang-mob d-block">
            {currentLang === 'ar'
              ? (
                <NavItem className="cursor-pointer d-flex align-items-center" onClick={() => changeLanguage('en')}>
                  <img title="اللغه" className="lang-img" src={EN} alt="EN" />
                  {/* <span className="lang-span mx-3 mx-lg-0"> اللغه   </span> */}
                </NavItem>
              )
              : (
                <NavItem className="cursor-pointer align-items-center" onClick={() => changeLanguage('ar')}>
                  <img title="Language" className="lang-img" src={AR} alt="AR" />
                  {/* <span className="lang-span mx-3 mx-lg-0">  Language</span> */}
                </NavItem>
              )}

          </ul>

          <div className="row align-items-center w-100">
            <div className="col-lg-3">
              <NavLink to="/" className="navbar-brand">
                <img className="logoWhite" src={logo} alt="Iticket-logo" />
                <img className="logoDark" src={logoDark} alt="Iticket-logo" />
              </NavLink>
            </div>
            <div className="col-9">
              <div className={`row linksContainer align-items-center ${showNav && 'open-nav'}`}>
                <div className="col-lg-8">
                  <Nav className="d-flex  middle-side-container " navbar>
                    <NavItem>
                      <NavLink activeClassName="activLink" className="nav-link  text-decoration-none text-grey" to="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink activeClassName="activLink" className="nav-link text-decoration-none text-grey" to="/upcoming-events">Browse Events</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink activeClassName="activLink" className="nav-link  text-decoration-none text-grey" to="/ss">Support</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink activeClassName="activLink" className="nav-link  text-decoration-none text-grey" to="/about">About Us</NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-3 col-8">
                  <Nav className="justify-content-evenly d-flex flex-sm-row flex-column align-items-center last-side-container" navbar>
                    <div className="lang-desktop d-none">
                      {currentLang === 'ar'
                        ? (
                          <NavItem className="cursor-pointer d-flex align-items-center" onClick={() => changeLanguage('en')}>
                            <img title="اللغه" className="lang-img" src={EN} alt="EN" />
                            {/* <span className="lang-span mx-3 mx-lg-0"> اللغه   </span> */}
                          </NavItem>
                        )
                        : (
                          <NavItem className="cursor-pointer align-items-center" onClick={() => changeLanguage('ar')}>
                            <img title="Language" className="lang-img" src={AR} alt="AR" />
                            {/* <span className="lang-span mx-3 mx-lg-0">  Language</span> */}
                          </NavItem>
                        )}

                    </div>
                    {auth?.authenticated ? (
                      <>
                        <NavItem className="my-3">
                          <div ref={notificationRef} className="notification-nav">
                            <div onClick={() => setShowNotificationDropdown(!showNotificationDropdown)} className="notification">
                              <span className="noti" />
                              <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.90528 24.1325C10.5528 24.8537 11.384 25.25 12.2465 25.25H12.2478C13.114 25.25 13.949 24.8537 14.5978 24.1312C14.9453 23.7475 15.5378 23.7162 15.9215 24.0625C16.3065 24.4087 16.3378 25.0025 15.9915 25.3862C14.9815 26.5075 13.6528 27.125 12.2478 27.125H12.2453C10.844 27.1237 9.51778 26.5062 8.51153 25.385C8.16528 25.0012 8.19653 24.4075 8.58153 24.0625C8.96653 23.715 9.55903 23.7462 9.90528 24.1325ZM12.3088 0.25C17.865 0.25 21.5975 4.5775 21.5975 8.61875C21.5975 10.6975 22.1263 11.5787 22.6875 12.5137C23.2425 13.4362 23.8713 14.4837 23.8713 16.4637C23.435 21.5225 18.1538 21.935 12.3088 21.935C6.46378 21.935 1.18128 21.5225 0.750017 16.5437C0.746283 14.4837 1.37503 13.4362 1.93003 12.5137L2.12596 12.1839C2.60838 11.3548 3.02003 10.4529 3.02003 8.61875C3.02003 4.5775 6.75253 0.25 12.3088 0.25ZM12.3088 2.125C7.94003 2.125 4.89503 5.5475 4.89503 8.61875C4.89503 11.2175 4.17378 12.4187 3.53628 13.4787C3.02503 14.33 2.62128 15.0025 2.62128 16.4637C2.83003 18.8212 4.38628 20.06 12.3088 20.06C20.1875 20.06 21.7925 18.7662 22 16.3825C21.9963 15.0025 21.5925 14.33 21.0813 13.4787C20.4438 12.4187 19.7225 11.2175 19.7225 8.61875C19.7225 5.5475 16.6775 2.125 12.3088 2.125Z" fill="#9598AE" stroke="#9598AE" strokeWidth="0.5" />
                              </svg>
                            </div>
                            <div className={`notification-nav_content mb-3 py-2 py-md-3 ${showNotificationDropdown ? 'show' : ''}`}>
                              <h5 className="title font-weight-medium mb-2  px-2 px-md-3">
                                <span className="noti" />
                                Notification
                              </h5>
                              <ul className="list-unstyled">
                                <li className="active d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex align-items-baseline justify-content-center w-100 py-2 py-md-3 px-3">
                                  <span className="noti" />
                                  <div className="">
                                    <h6 className="noti-title font-weight-regular mb-0">
                                      <span className="font-weight-semiBold">Two days</span>
                                      {' '}
                                      left until
                                      {' '}
                                      <span className="font-weight-semiBold">LUCIEN FOORT’s</span>
                                      {' '}
                                      event
                                    </h6>
                                    <span className="time">
                                      Today at 12:01 AM
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </NavItem>
                        <NavItem className="my-3">
                          <div ref={profileRef} className="profile-nav">
                            {user?.profile_image?.path ? (
                              <img
                                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                                src={user.profile_image?.path}
                                alt="User"
                                className="user-avatar"
                              />
                            ) : (
                              <img onClick={() => setShowProfileDropdown(!showProfileDropdown)} src={avatar} alt="Avatar" className="user-avatar " />
                            )}
                            <ul className={`list-unstyled profile-nav_content p-2 ${showProfileDropdown ? 'show' : ''}`}>
                              <li className="d-flex align-items-center mb-2 p-2">
                                <Link to="/my-tickets/all">
                                  <span className="icon">
                                    <img src={ticketIcon} alt="ticketIcon" />
                                  </span>
                                  <span className="text font-weight-medium">
                                    My Tickets
                                  </span>
                                </Link>
                              </li>

                              <li className="d-flex align-items-center mb-2 p-2">
                                <Link to="/">
                                  <span className="icon">
                                    <img src={cardIcon} alt="Payments" />
                                  </span>
                                  <span className="text font-weight-medium">
                                    Payments
                                  </span>
                                </Link>
                              </li>

                              <li className="d-flex align-items-center mb-2 p-2 pb-4">
                                <Link to="/settings">
                                  <span className="icon">
                                    <img src={settingIcon} alt="ticketIcon" />
                                  </span>
                                  <span className="text font-weight-medium">
                                    Settings
                                  </span>
                                </Link>
                              </li>
                              <button onClick={handleSignOut} className="main-button primary w-100 my-2">LOGOUT</button>
                            </ul>
                          </div>
                        </NavItem>
                      </>
                    ) : (
                      <>
                        <NavItem className="my-3">
                          <NavLink onClick={() => toggleSignUpModal(true)} className="signUp mx-2">Sign Up</NavLink>
                        </NavItem>
                        <NavItem className="my-3">
                          <NavLink onClick={() => toggleLoginModal(true)} className="main-button primary">Login</NavLink>
                        </NavItem>
                      </>
                    )}
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </Navbar>
      </header>
      <Login isModalOpen={isLoginModalOpen} toggleModal={toggleLoginModal} toggleForgetPassModal={toggleForgetPassModal} />
      <SignUp isModalOpen={isLSignUpModalOpen} openSignInModal={toggleLoginModal} toggleModal={toggleSignUpModal} />
      {/* <FoegetPassword openForgetPassModal={openForgetPassModal} toggleForgetPassModal={toggleForgetPassModal} /> */}
    </>
  );
}

export default Header;
