/* eslint-disable default-param-last */
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_USER':
    case 'UPDATE_USER':
      if (action.error) return state;
      return {
        ...state,
        user: action.data.data,
      };
    case 'OTP_VERIFIED':
      if (action.error) return state;
      return {
        ...state,
        user: action.data,
      };
    case 'GET_LANGUAGE':
    case 'UPDATE_LANGUAGE':
      if (action.error) return state;
      console.log(action.data.data, 'reducer');
      return {
        ...state,
        language: action.data.data,
      };
    default:
      return state;
  }
};

export default userReducer;
