import React, { useState, useRef } from 'react';
import { InputGroup, InputGroupText } from 'reactstrap';
import Header from '../layout/header';
import icon from '../../assets/images/icons/home-icon.svg';
import SearchDropDowns from './SearchDropDowns';

function Banner() {
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [showDropdownsMobile, setShowDropdownsMobile] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 1) {
      setShowDropdowns(true);
      if (window.innerWidth < 500) {
        setShowDropdownsMobile(true);
      } else {
        setShowDropdownsMobile(false);
      }
    } else {
      setShowDropdowns(false);
      if (window.innerWidth < 500) {
        setShowDropdownsMobile(false);
      }
    }
  };
  window.addEventListener('click', () => {
    if (showDropdowns) {
      setShowDropdowns(false);
      setSearchValue('');
      if (window.innerWidth < 500) {
        setShowDropdownsMobile(false);
      }
    }
  });

  return (
    <>
      <div className="banner-layout">
        <Header />
      </div>
      <div className="banner-container">
        <div className="banner-container-content d-flex justify-content-center">
          <div className={` ${showDropdowns ? 'padding-tp' : ''} banner-container-text d-flex justify-content-center align-items-center flex-column m-auto text-center`}>
            <p>
              Let’s find your event!
            </p>
            <h5 className="banner-title text-white px-lg-5">
              Best Place to Find Events You
              {' '}
              <span>
                Love.
                <img src={icon} alt="" className="img-fluid" />
              </span>
            </h5>

            <span className="text-grey-light my-3">

              iticket is a platform that helps you find events near you, because we know it’s hard to find. Find yours now!
            </span>
            {/* <InputGroup className="banner-search-input w-100 d-lg-none">
              <InputGroupText>
                <span className="iticket-icon-search" />
              </InputGroupText>
              <input onChange={(e) => handleChangeMobile(e)} className="px-2" placeholder="Search by events, Concerts, Or Venue..." type="search" />
            </InputGroup> */}
            <form className={`${showDropdownsMobile ? 'absoluteForm' : ''} mt-lg-4`}>
              <InputGroup className="banner-search-input w-100">
                <InputGroupText>
                  <span className="iticket-icon-search" />
                </InputGroupText>
                <input value={searchValue} onChange={(e) => handleChange(e)} className="px-2" placeholder="Search by events, Concerts, Or Venue..." type="search" />
              </InputGroup>
              {showDropdowns
                && (
                  <div onClick={(e) => e.stopPropagation()} className="">
                    <SearchDropDowns />
                  </div>
                )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
