/* eslint-disable eqeqeq */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Autoplay, Pagination, EffectFade,
} from 'swiper';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ticketImage from '../../assets/images/book-ticket-image.png';
import mastercardImage from '../../assets/images/Mastercard-Logo.svg';
import applePayImage from '../../assets/images/apple-pay.png';
import Loading from '../../components/Loading';
import EasyLogin from '../auth/Easy-Login';
import { eventDetails } from '../../iticket-sdk/redux/actions/events.actions';
import { createBooking, saveBooking } from '../../iticket-sdk/redux/actions/booking.actions';
import { toast } from 'react-hot-toast';

function BookTicket() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit-card');
  const [isAuthMode, setIsAuthMode] = useState(false);
  const auth = useSelector((state) => state?.auth?.authenticated);
  const { i18n, t } = useTranslation();

  const [eventData, setEventData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [timesData, setTimesData] = useState([]);
  const [availTicks, setAvailTickes] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  const [bookingDataSummery, setBookingDataSummery] = useState([]);
  console.log(dateData.find((el)=> el.selected== true))
  useEffect(() => {
    dispatch(eventDetails(eventId)).then((response) => {
      setEventData(response.data);
      if (response?.data?.days?.length > 0) {
        response.data.days[0].selected = true;
        setDateData(response.data?.days);
        if (response?.data?.days[0]?.times.length > 0) {
          response.data.days[0].times[0].selected = true;
          setTimesData(response.data?.days[0]?.times);
          if (response.data?.days[0]?.times[0]?.tickets?.length) {
            response.data?.days[0]?.times[0].tickets.forEach((el) => {
              el.count = 0;
            });
            setAvailTickes(response.data?.days[0]?.times[0].tickets);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const ticketsData = [...availTicks];
    const tickets = {
      total: [],
      data: [],
    };
    ticketsData.forEach((el) => {
     if (el.count > 0) {
      tickets?.data.push(
          el
        );
      }
    })
    tickets.data.map((el)=>{
      tickets?.total.push(
        el.count * el?.price
      );
    })
    setBookingDataSummery(tickets);
  }, [availTicks]);

  const dateParams = {
    slidesPerView: 4,
    spaceBetween: 5,
    watchOverflow: 'true',
    // slidesPerColumn: '2',
    // loop: true,
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false,
    // },
    breakpoints: {
      320: {
        slidesPerView: 3.5,
        spaceBetween: 5,
      },
      640: {
        slidesPerView: 4.8,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 5.3,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 5.5,
        spaceBetween: 5,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 5,
      },
    },
  };
  const timeParams = {
    slidesPerView: 3.3,
    spaceBetween: 5,
    watchOverflow: 'true',
    breakpoints: {
      320: {
        slidesPerView: 2.6,
        spaceBetween: 5,
      },
      640: {
        slidesPerView: 4.2,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 4.4,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 4.5,
        spaceBetween: 5,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 5,
      },
    },
  };

  function handleSubmit() {
    // setLoading(true);
    const ticketsData = [...availTicks];
    const data = {
      event_id: eventId,
      tickets: [],
    };
    if (availTicks.filter(tic => tic.count < tic.limit_per_customer.min).length === availTicks.length) {
      toast.error('please choose one ticket at least');
    }
    ticketsData.forEach((el) => {
      if (el.count > 0 && el.count < el.limit_per_customer.min) {
        el.errMsg = ` Min Tickets Is ${el.limit_per_customer.min} `;
        setAvailTickes(ticketsData);
      } else if (el.count > 0 && el.count >= el.limit_per_customer.min) {
        data.tickets.push(
          {
            ticket_id: el.id,
            qty: el.count,
          },
        );
        if (!auth) {
          setBookingData(data);
          setCurrentStep(1);
          setIsAuthMode(true)
          window.scrollTo(0, 0);
        } else {
          dispatch(createBooking(data)).then((res) => {
            console.log(res);
            window.scrollTo(0, 0);
            if (auth) {
              setCurrentStep(2);
            } else {
              setCurrentStep(1);
            }
            setLoading(false);
          }).catch((err) => {
            console.log(err);
            setLoading(false);
          });
        }
      }
    });
  
  }
  /// select date
  const handleSelectDate = (item) => {
    if (item.selected !== true) {
      const allDates = [...dateData];
      allDates?.forEach((el) => {
        if (el.date_formated === item.date_formated) {
          el.selected = !el.selected;
          el.times[0].selected = true;
          setTimesData(el.times);
          if (el?.times[0]?.tickets?.length) {
            el?.times[0]?.tickets.forEach((el) => {
              el.count = 0;
            });
            setAvailTickes(el?.times[0]?.tickets);
          }
        } else {
          el.selected = false;
        }
      });
      setDateData(allDates);
    }
  };
  /// select time
  const handleSelectTime = (item) => {
    if (item.selected !== true) {
      const allTimes = [...timesData];
      allTimes.forEach((el) => {
        if (el?.formated_time === item?.formated_time) {
          el.selected = !el.selected;
          if (el?.times[0]?.tickets.length) {
            el?.times[0]?.tickets.forEach((el) => {
              el.count = 0;
            });
            setAvailTickes(el?.times[0]?.tickets);
          }
        } else {
          el.selected = false;
        }
      });
      setTimesData(allTimes);
    }
  };

  function handleUpdateTicketsCount(item, add) {
    const selectedTic = availTicks.findIndex(((tic) => tic.id === item.id));
    const newArr = [...availTicks];
    newArr[selectedTic] = {
      ...availTicks[selectedTic],
      count: add && item.limit_per_customer.max > item.count ? availTicks[selectedTic]?.count + 1 : add && item.limit_per_customer.max == item.count ? availTicks[selectedTic]?.count : availTicks[selectedTic]?.count - 1,
      errMsg: add && item.limit_per_customer.max == item.count ? ` Max Tickets Is ${item.limit_per_customer.max} ` : '',
    };
    setAvailTickes(newArr);
  }
  // payment
  useEffect(() => {
    if (paymentMethod === 'credit-card' && currentStep === 2) {
      Moyasar.init({
        element: '.mysr-form',
        language: i18n.language,
        // Amount in the smallest currency unit.
        // For example:
        // 10 SAR = 10 * 100 Halalas
        // 10 KWD = 10 * 1000 Fils
        // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
        fixed_width: false,
        amount: 10000,
        currency: 'SAR',
        description: 'Coffee Order #1',
        publishable_api_key: 'pk_test_AQpxBV31a29qhkhUYFYUFjhwllaDVrxSq5ydVNui',
        // publishable_api_key: process.env.REACT_APP_MOYASSER_KEY,
        callback_url: `${process.env.REACT_APP_API_URL}moyasar/payment/check`,
        methods: ['creditcard'],
        credit_card: {
          save_card: true,
        },

        on_completed: function (payment) {
          return dispatch(saveBooking(payment));
        },
        on_failure: function (error) {
          console.log('error', error)
        }
      })
    }
  }, [i18n.language, paymentMethod, currentStep])

  return (
    <div className="book-ticket_page mt-4 mt-md-5 pb-4 pb-md-5">
      {loading && <Loading />}

      {/* <div className="container">
        <div className="mysr-form"></div>
      </div> */}

      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-lg-10 my-2">
            {/* Book Header */}
            <div className="book-header">
              <div className="d-flex flex-column flex-lg-row justify-content-between ">
                <div className="">
                  <Link className="main-button secondary lg text-uppercase mb-4" to={`/events/event-details/${eventId}`}>
                    <span className="mx-2">
                      <span className="iticket-icon-arrow-down arrow-left d-inline-block" />
                    </span>
                    <span className="mx-2">
                      Event details
                    </span>
                  </Link>
                  {/* BreadCrumb */}
                  <div className="breadcrumb d-flex align-items-center">
                    <Link to="/">
                      Home
                    </Link>
                    <span className="icon mx-2 mx-lg-3">
                      <span className="iticket-icon-arrow-down arrow-right d-inline-block" />
                    </span>
                    <Link to={`/events/event-details/${eventId}`}>
                      Event Details
                    </Link>
                    <span className="icon mx-2 mx-lg-3">
                      <span className="iticket-icon-arrow-down arrow-right d-inline-block" />

                    </span>
                    <div className="text font-weight-medium">
                      {currentStep === 0 ? 'Booking a Ticket' : currentStep === 1 ? 'Information' : 'Payment'}
                    </div>
                  </div>
                </div>
                {/* STEPS */}
                <div className="steps d-flex flex-column align-items-center my-2">
                  <div className="d-flex">
                    <div className={`line ${currentStep === 0 ? 'active' : currentStep > 0 ? 'active done' : ''}`} />
                    {(!auth ||(auth && isAuthMode))
                      ? <div className={`line ${currentStep === 1 ? 'active' : currentStep > 1 ? 'active done' : ''}`} /> : ''}
                    <div className={`line ${currentStep === 2 ? 'active' : ''}`} />
                  </div>
                  <div className="titles mt-4 d-flex justify-content-between align-items-center">
                    <h6 className={`title ${currentStep >= 0 ? 'active' : ''}`}>Booking a Ticket</h6>
                    {(!auth ||(auth && isAuthMode))
                      ? <h6 className={`title ${currentStep >= 1 ? 'active' : ''}`}>Information</h6> : ''}
                    <h6 className={`title ${currentStep === 2 ? 'active' : ''}`}>Payment</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-2">
            <div className="book-body my-2">
              <h3 className="title text-uppercase font-weight-semiBold pb-1 mb-3">
                {currentStep === 0 ? 'Booking a Ticket' : currentStep === 1 ? 'Information' : 'Payment'}
              </h3>
              {/* step details */}
              {/* First step ===> BOOK A TICKET */}
              {currentStep === 0 && (
                <>
                  {/* DATE */}
                  <h6 className="subtitle text-uppercase font-weight-semiBold">
                    date
                  </h6>
                  <div className="date-container d-flex flex-wrap my-3">
                    <Swiper className="w-100" {...dateParams}>
                      {dateData?.map((el) => (
                        <SwiperSlide key={el?.date_formated} className="slider-slide" onClick={() => handleSelectDate(el)}>
                          <div className={`date-box box d-flex flex-column justify-content-center align-items-center p-2 ${el?.selected ? 'active' : ''}`}>
                            <span className="font-weight-medium text-center mb-2">{el.date_formated}</span>
                            {/* <span className="font-weight-semiBold">{el.day}</span> */}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  {/* Time */}
                  <h6 className="subtitle text-uppercase font-weight-semiBold">
                    Time
                  </h6>
                  <div className="time-container d-flex flex-wrap my-3">
                    <Swiper className="w-100" {...timeParams}>
                      {timesData?.map((el) => (
                        <SwiperSlide key={el?.formated_time} className="slider-slide" onClick={() => { handleSelectTime(el); }}>
                          <div className={`time-box box d-flex justify-content-center align-items-center ${el.selected ? 'active' : ''}`}>
                            {el.formated_time}
                          </div>
                        </SwiperSlide>
                      ))}

                    </Swiper>
                  </div>
                  {/* Tickets */}
                  <div className="row">
                    <div className="col-lg-8 col-md-10">
                      <h6 className="subtitle text-uppercase font-weight-semiBold my-4">
                        Tickets
                      </h6>
                      {availTicks.length > 0
                        ? (
                          <div className="tickets my-3">
                            {availTicks?.map((ticket) => (
                              <div key={ticket.id} className="ticket py-3">
                                <h6 className="text-uppercase font-weight-semiBold">
                                  {ticket?.type?.en}
                                </h6>
                                <div className="d-flex justify-content-between">
                                  <p className="text m-0">
                                    {ticket?.desc?.en}
                                  </p>
                                  <div className="actions d-flex align-items-center">
                                    <div className={`num-box ${ticket?.count === 0 ? 'disabled' : ''} `} onClick={() => handleUpdateTicketsCount(ticket)}>
                                      <span className="iticket-icon-minues fw-bold" />
                                    </div>
                                    <span className="font-weight-semiBold mx-3">{ticket.count}</span>
                                    <div onClick={() => handleUpdateTicketsCount(ticket, true)} className="num-box">
                                      <span className="iticket-icon-plus fw-bold" />
                                    </div>
                                  </div>

                                </div>
                                <p className="text-danger">
                                  {' '}
                                  {ticket.errMsg}
                                  {' '}
                                </p>
                              </div>
                            ))}
                          </div>
                        )
                        : 'No Available Tickets'}
                    </div>
                  </div>
                  <div className="row d-none d-lg-block mt-3">
                    <div className="col-lg-6">
                      <button type="button" onClick={handleSubmit} className="main-button primary lg text-uppercase  w-100">
                        <span className="mx-2">
                          {currentStep === 0 ? 'Next' : currentStep === 1 ? 'payment' : 'Complete & pay'}
                        </span>
                        {currentStep !== 2 && (<span className="iticket-icon-arrow-down arrow-right d-inline-block  mx-2" />)}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* Seconad Step ===> Information easy login */}
              {currentStep === 1 && !auth ? (
                <EasyLogin setCurrentStep={setCurrentStep} bookingData={bookingData} />
              ) : ''}

              {/* Third Step ===> Payment */}
              {currentStep === 2 && (
                <div className="row">
                  <div className="col-md-6 my-2">
                    <div className="payment-type d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                      <div className="label text-uppercase font-weight-semiBold my-2">
                        payment
                      </div>
                      {navigator.userAgent.search('Mac') !== -1 && (<div key="payment-type" className="d-flex my-2">
                        {/* <Form.Check
                          type="radio"
                          label="Paypal"
                          name="payment"
                          id="paypal"
                          checked={paymentMethod === 'paypal'}
                          onChange={(e) => setPaymentMethod('paypal')}
                        /> */}
                        <Form.Check
                          name="payment"
                          type="radio"
                          label="Credit card"
                          id="credit-card"
                          checked={paymentMethod === 'credit-card'}
                          onChange={(e) => setPaymentMethod('credit-card')}
                        />
                        <Form.Check
                          name="payment"
                          type="radio"
                          label="Apple Pay"
                          id="apple-pay"
                          checked={paymentMethod === 'apple-pay'}
                          onChange={(e) => setPaymentMethod('apple-pay')}
                        />
                      </div>)}
                    </div>

                    {paymentMethod === 'credit-card' && (
                      <>
                      <div className="mysr-form"></div>
                        {/* wallet */}
                        {/* <Form.Check
                          type="checkbox"
                          id="default-checkbox"
                          label="Use wallet balance (SAR 215)"
                          className="checkbox d-flex align-items-center py-3"
                        /> */}

                        {/* <div className="input-container mb-3 mb-md-4">
                          <label htmlFor="card_number" className="text-uppercase font-weight-semiBold mb-2">Card number</label>
                          <div className="payment-input">
                            <input type="text" id="card_number" name="card_number" className="w-100 px-3" placeholder="xxxx     xxxx     xxxx     xxxx" />
                            <div className="icon">
                              <img src={mastercardImage} alt="mastercard Image" />
                            </div>
                          </div>
                        </div>

                        <div className="input-container mb-3 mb-md-4">
                          <label htmlFor="card_holder" className="text-uppercase font-weight-semiBold mb-2">cARD HOLDER</label>
                          <input type="text" id="card_holder" name="card_holder" className="w-100 px-3" placeholder="ahmed ali hassan" />
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-container mb-3 mb-md-4">
                              <label htmlFor="expiration_date" className="text-uppercase font-weight-semiBold mb-2">Expiration date</label>
                              <input type="text" id="expiration_date" name="expiration_date" className="w-100 px-3" placeholder="MM   /   YY" />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-container mb-3 mb-md-4">
                              <label htmlFor="CVC" className="text-uppercase font-weight-semiBold mb-2">CVC</label>
                              <input type="text" id="CVC" name="CVC" className="w-100 px-3" placeholder="XXX" />
                            </div>
                          </div>
                        </div> */}
                        {/* Save card */}
                        {/* <Form.Check
                          type="checkbox"
                          id="save-card"
                          label="Save card for next purchases"
                          className="checkbox d-flex align-items-center"
                        /> */}
                      </>
                    )}

                    {paymentMethod === 'apple-pay' && (
                      <>
                        {/*  */}
                        <Form.Check
                          type="checkbox"
                          id="default-checkbox"
                          label="Use wallet balance (SAR 215)"
                          className="checkbox d-flex align-items-center py-3"
                        />
                        <div className="input-container mb-3 mb-md-4">
                          <label htmlFor="card_number" className="text-uppercase font-weight-semiBold mb-2">Card number</label>
                          <div className="payment-input">
                            <input type="text" id="card_number" name="card_number" className="w-100 px-3" placeholder="xxxx     xxxx     xxxx     xxxx" />
                            <div className="icon">
                              <img alt="apple Pay Image" src={applePayImage} />
                            </div>
                          </div>
                        </div>

                        <div className="input-container mb-3 mb-md-4">
                          <label htmlFor="card_holder" className="text-uppercase font-weight-semiBold mb-2">cARD HOLDER</label>
                          <input type="text" id="card_holder" name="card_holder" className="w-100 px-3" placeholder="ahmed ali hassan" />
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-container mb-3 mb-md-4">
                              <label htmlFor="expiration_date" className="text-uppercase font-weight-semiBold mb-2">Expiration date</label>
                              <input type="text" id="expiration_date" name="expiration_date" className="w-100 px-3" placeholder="MM   /   YY" />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-container mb-3 mb-md-4">
                              <label htmlFor="CVC" className="text-uppercase font-weight-semiBold mb-2">CVC</label>
                              <input type="text" id="CVC" name="CVC" className="w-100 px-3" placeholder="XXX" />
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <Form.Check
                          type="checkbox"
                          id="save-card"
                          label="Save card for next purchases"
                          className="checkbox d-flex align-items-center"
                        />
                      </>
                    )}

                  </div>
                  {/* line */}
                  <div className="col-md-1 d-flex justify-content-center">
                    <div className="line" />
                  </div>
                  <div className="col-md-5 my-2">
                    {/* Promo Code */}
                    <form className="promo-code">
                      <div className="label text-uppercase font-weight-semiBold my-2">
                        Promo Code
                      </div>
                      <div className="input-container mb-3 mb-md-4">
                        <input type="text" id="promo-code" name="promo-code" className="w-100 px-3" placeholder="xxxxxxxx" />
                        <button type="button" onClick={handleSubmit} className="main-button primary text-uppercase  px-3">
                          Apply Code
                        </button>
                      </div>
                    </form>

                    {/* Summary */}
                    <div className="summary mt-4">
                      <div className="summary-details px-2 px-md-3">
                        <div className="label text-uppercase font-weight-semiBold my-2 my-md-3">
                          Summary
                        </div>
                        <p className="d-flex justify-content-between mb-2 mb-md-3">
                          <span>2  x  VVIP Tickets</span>
                          <span>1,250 SR</span>
                        </p>
                        <p className="d-flex justify-content-between mb-md-3">
                          <span>VAT (%15)</span>
                          <span>120 SR</span>
                        </p>
                        <p className="d-flex justify-content-between  mb-md-3">
                          <span>Wallet Discount</span>
                          <span>-  185 SR</span>
                        </p>
                        <p className="d-flex justify-content-between  mb-md-3">
                          <span>Promo Code</span>
                          <span>-  65 SR</span>
                        </p>
                      </div>
                      {/* TOTAL */}
                      <div className="total px-3 px-md-4 mt-3 d-flex justify-content-between align-items-center">
                        <span className="font-weight-semiBold">Total</span>
                        <span className="font-weight-semiBold">2,230.30 SR</span>
                      </div>
                    </div>
                  </div>

                </div>
              )}

              {/* BUTTON SUBMIT ===> for desktop */}
              {/* <div className="row d-none d-lg-block mt-3">
                <div className="col-lg-6">
                  <button type="button" onClick={handleSubmit} className="main-button primary lg text-uppercase  w-100">
                    <span className="mx-2">
                      {currentStep === 0 ? 'Next' : currentStep === 1 ? 'payment' : 'Complete & pay'}
                    </span>
                    {currentStep !== 2 && (<span className="iticket-icon-arrow-down arrow-right d-inline-block  mx-2" />)}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* book ticket card & summary */}
          <div className="col-lg-4 col-md-7 col-sm-9 my-2">
            <div className="booking-ticket_card p-3 mx-0 mx-lg-3">
              <img src={ticketImage} alt="" className="img-fluid w-100" />
              <h6 className="badge font-weight-semiBold text-uppercase d-flex justify-content-center align-items-center my-2 my-md-3">
                CONCERt
              </h6>
              <div className="title font-weight-semiBold text-uppercase">
              {eventData?.title}
              </div>
              <div className="border-dashed my-4" />
              {/* other details */}
              <div className="details py-2">
                <div className="mb-2 mb-md-3">
                  <h6 className="label mb-0 font-weight-semiBold text-uppercase">
                    LOCATIOn
                  </h6>
                  <h6 className="info font-weight-semiBold mb-0">
                    {eventData?.locations?.address}
                  </h6>
                </div>
                <div className="row mb-2 mb-md-3">
                  <div className="col-6">
                    <h6 className="label mb-0 mb-0 font-weight-semiBold text-uppercase">
                      DATE
                    </h6>
                    <h6 className="info font-weight-semiBold mb-0">
                      {dateData?.find((el)=> el.selected== true)?.date_formated}
                    </h6>
                  </div>
                  <div className="col-6">
                    <h6 className="label mb-0 mb-0 font-weight-semiBold text-uppercase">
                      TIME
                    </h6>
                    <h6 className="info font-weight-semiBold mb-0">
                    {timesData?.find((el)=> el.selected== true)?.formated_time}
                    </h6>
                  </div>
                </div>
                {bookingDataSummery?.data?.length > 0 &&
                <div className="row">
                  <div className="col-6">
                    <h6 className="label mb-0 font-weight-semiBold text-uppercase">
                      SEAT NO.
                    </h6>
                    <h6 className="info font-weight-semiBold mb-0">
                      A50
                    </h6>
                  </div>
                  <div className="col-6">
                    <h6 className="label mb-0 font-weight-semiBold text-uppercase">
                      TICKETS TYPE
                    </h6>
                    <h6 className="info font-weight-semiBold mb-0">
                      {bookingDataSummery?.data?.map((ticket)=>(
                <>
                  <p className="d-flex justify-content-between mb-2 mb-md-3">
                    <span> {ticket?.count}  x  {ticket?.type?.en}</span>
                  </p>
                  </>
                    ))}
                    </h6>
                  </div>
                </div> }
              </div>
            </div>
            {/* Summary */}
            {currentStep === 0 && (
              <div className="summary mt-4 pt-3">
             {bookingDataSummery?.data?.length > 0 &&
                <div className="summary-details px-2 px-md-4">
                  <h5 className="title mb-3 font-weight-semiBold text-uppercase">
                    Summary
                  </h5>
                  {bookingDataSummery?.data?.map((ticket)=>(
                <>
                  <p className="d-flex justify-content-between mb-2 mb-md-3">
                    <span> {ticket?.count}  x  {ticket?.type?.en}</span>
                    <span>{ticket?.count * ticket?.price}  SR</span>
                  </p>
                  </>
                    ))}
                          <p className="d-flex justify-content-between  mb-2 mb-md-3">
                    <span>VAT (%15)</span>
                    <span>120 SR</span>
                  </p>
                </div>
             }              
                {/* TOTAL */}
                {bookingDataSummery?.data?.length > 0 &&
                <div className="total px-3 px-md-4 mt-3 d-flex justify-content-between align-items-center">
                  <span className="font-weight-semiBold">Total</span>
                  <span className="font-weight-semiBold">
                    {bookingDataSummery.total.reduce( (accumulator, currentValue) => accumulator + currentValue)}
                     SR</span>
                </div>
               }
              </div>
            )}
          </div>

          {/* BUTTON SUBMIT ===> for mobile */}
          {currentStep === 0  && (<div className="col-lg-7 col-md-7 col-sm-9 d-block d-lg-none mt-3">
            <button type="button" onClick={handleSubmit} className="main-button primary lg text-uppercase  w-100">
              <span className="mx-2">
                {currentStep === 0 ? 'Next' : currentStep === 1 ? 'payment' : 'Complete & pay'}
              </span>
              {currentStep !== 2 && (<span className="iticket-icon-arrow-down arrow-right d-inline-block  mx-2" />)}
            </button>
          </div>)}

        </div>
      </div>
    </div>
  );
}

export default BookTicket;
