import HttpHelpers from './helpers';

const HomeApiEndpoints = {
  subscribe: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('newsletter/subscribe', data)
      .then((response) => response.data);
  },
  fetchFAQ: () => {
    return HttpHelpers.authenticatedAxios
      .get('faq')
      .then((response) => response.data);
  },
  getSocialAccounts: () => {
    return HttpHelpers.authenticatedAxios
      .get('social-accounts')
      .then((response) => response.data);
  },
  getPages: () => {
    return HttpHelpers.authenticatedAxios
      .get('pages')
      .then((response) => response.data);
  },
  getPage: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`pages/${id}`)
      .then((response) => response.data);
  },
  getAllCategories: () => {
    return HttpHelpers.authenticatedAxios
      .get('categories')
      .then((response) => response.data);
  },
};

export default HomeApiEndpoints;
