import { combineReducers } from 'redux';
import homeReducer from './home.reducer';
import authReducer from './auth.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  user: userReducer,
});

export default rootReducer;
