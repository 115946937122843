/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo.svg';
import paymentMethods from '../../../assets/images/payment-methods.png';

function BasicFooter() {
  const { t } = useTranslation();

  return (
    <footer className="">
      <div className="container">
        <div className="footer-bottom py-3 mt-21 mt-md-4">
          <div className="row">
            <div className="col-md-6 my-2 d-flex justify-content-center justify-content-md-start">
              <div className="d-flex flex-column flex-md-row align-items-center">
                <Link to="/">
                  <img src={logo} alt="Iticket-logo" className="footer-logo my-1" />
                </Link>
                <p className="copywrite mb-0 font-weight-light cl-light my-1">
                  © 2021 iticket. All rights reserved.
                </p>
              </div>
            </div>
            <div className="col-md-6 my-2 d-flex justify-content-center justify-content-md-end">
              <img src={paymentMethods} width="235px" className="paymentMethods" alt="paymentMethods" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default BasicFooter;
