/* eslint-disable max-len */
import React from 'react';
// import AsyncSelect   from 'react-select';
import Select from 'react-select';
import {
  InputGroup, Col, Row, InputGroupText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import selectStyles from '../../globals/styles-count';
import mic from '../../assets/images/events/mic.svg';
import banner from '../../assets/images/partners-banner.png';
import cinema from '../../assets/images/events/cinema.svg';
import art from '../../assets/images/events/art.svg';

function SearchDropDowns({ handleChange, placeholder }) {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  return (
    <div className="search-dropDowns-container">
      <div className="">
        <hr />
        <h3 className="px-5 py-2">Categories</h3>
        <Row className="px-5 tex-center">
          <Col sm={6} md={4} className="mb-3 select-location-dropdown">
            <InputGroup className="search-dropdowns-home input-group-merge">
              <InputGroupText>
                <img src={mic} alt="mic" />
              </InputGroupText>
              <Select
                cacheOptions
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                defaultOptions
                options={options}
                // onChange={handleChange}
                placeholder="Concert (26)"
                styles={selectStyles}
              />
            </InputGroup>
          </Col>
          <Col sm={6} md={4} className="mb-3 select-location-dropdown">
            <InputGroup className="search-dropdowns-home input-group-merge">
              <InputGroupText>
                <img src={cinema} alt="Comedy" />
              </InputGroupText>
              <Select
                cacheOptions
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                defaultOptions
                options={options}
                // onChange={handleChange}
                placeholder="Comedy (5)"
                styles={selectStyles}
              />
            </InputGroup>
          </Col>
          <Col sm={6} md={4} className="mb-3 select-location-dropdown">
            <InputGroup className="search-dropdowns-home input-group-merge">
              <InputGroupText>
                <img src={art} alt="art" />
              </InputGroupText>
              <Select
                cacheOptions
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                defaultOptions
                options={options}
                // onChange={handleChange}
                placeholder="Art (16)"
                styles={selectStyles}
              />
            </InputGroup>
          </Col>
        </Row>
        <hr />
        <h3 className="px-5 py-2">Events</h3>
        <div className="event-parent-container px-5">
          {[1, 2, 3, 4, 5].map((el) => (
            <div className="event-container mb-3">
              <Link className="d-flex align-items-center" to="/events/event-details/2">
                <img src={banner} className="img-fluid" alt="" />
                <h6 className="px-3">Manuel De La Mare jam</h6>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SearchDropDowns;
