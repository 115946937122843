import HttpHelpers from './helpers';

const AuthApiEndpoints = {
  login: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/login', data)
      .then((response) => response.data);
  },
  verifyLogin: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .get('check-auth', data)
      .then((response) => response.data);
  },
  signUp: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/register', data)
      .then((response) => response.data);
  },

  confirmOtp: (otp, code) => {
    return HttpHelpers.authenticatedAxios
      .post(`otp/${otp}/confirm`, code)
      .then((response) => response.data);
  },

  changePassword: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/profile/update/password', data)
      .then((response) => response.data);
  },
  forgetPassword: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('forget-password/phone', data)
      .then((response) => response.data);
  },
  verifyOtp: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('forget-password/phone/verify', data)
      .then((response) => response.data);
  },

  resetPassword: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('forget-password/phone/reset', data)
      .then((response) => response.data);
  },

  confirmEmail: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`user/confirm-email/${id}`)
      .then((response) => response.data);
  },
  easyLogin: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/easy_login', data)
      .then((response) => response.data);
  },
};

export default AuthApiEndpoints;
