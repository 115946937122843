import React from 'react';
import { Link } from 'react-router-dom';
import cardImg from '../../assets/images/card.png';

function Card({ card, lang }) {
  return (
    <div className="card-container">
      <Link to={`/events/event-details/${card?.id}`}>
        <img src={card?.poster?.url} alt="" />
      </Link>
      <Link to={`/events/event-details/${card?.id}`}>
        <h6 className="mt-3">{card?.title}</h6>
      </Link>
      <div className="d-flex justify-content-between align-items-center">
        <p className="d-flex date-container align-items-center">
          <span>
            {new Date(card?.start_sale_date).toLocaleDateString(lang === 'ar' ? 'ar-EG' : 'en-us', { month: 'short', day: 'numeric' })}
          </span>
          <span> </span>
          <span>
            {card?.category?.title}
            ,
            {card?.locations?.address}
          </span>
        </p>
        <span className="event-duration">
          Start from
          {' '}
          {card?.min_ticket_price}
        </span>
      </div>

    </div>
  );
}

export default Card;
