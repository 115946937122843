import React from 'react';
// import AsyncSelect   from 'react-select';
import Select from 'react-select';
import { InputGroup, InputGroupText } from 'reactstrap';
import selectStyles from '../../globals/styles';
import location from '../../assets/images/icons/map-pin.svg';

function SearchDropdown({ options, handleChange, placeholder }) {
  return (
    <InputGroup className="input-group-merge ">
      <InputGroupText>
        <img src={location} alt={location} />
      </InputGroupText>
      <Select
        cacheOptions
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultOptions
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        styles={selectStyles}
        //   value={value}
        isClearable
        // menuIsOpen
      />
    </InputGroup>
  );
}

export default SearchDropdown;
