/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Shimmer } from 'react-shimmer';
import Loading from '../../components/Loading';
import HomeApiEndpoints from '../../iticket-sdk/services/home.api';

function FAQ() {
  const { i18n, t } = useTranslation();
  const { data: faqs, isLoading } = useQuery(
    ['faq', i18n.language],
    HomeApiEndpoints.fetchFAQ,
    {
      staleTime: 30000,
      refetchOnMount: false,
      select: (data) => {
        return data?.data;
      },
    },
  );
  return (
    <div className="faq-page">
      {/* {isLoading && <Loading />} */}
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            {/* Head */}
            <div className="header text-center">
              <h2 className="title font-weight-semiBold">
                Here are the answers for all your questions!
              </h2>
              <p className="subtitle mt-3 mb-4 mb-md-5">
                Urna eu sed nunc amet quam consequat sapien commodo. Sem morbi a lobortis id lorem interdum rhoncus faucibus.
              </p>
            </div>

            {/* faqs */}
            <Accordion defaultActiveKey="0">
              {isLoading && [1, 2, 3].map((index) => (
                <Shimmer key={index} className="w-100 mb-3 rounded" height={60} width={100} />
              ))}
              {faqs?.map((faq, index) => (
                <Accordion.Item key={index} eventKey={index} className="mb-3">
                  <Accordion.Header>{faq?.question}</Accordion.Header>
                  <Accordion.Body>
                    {faq?.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
