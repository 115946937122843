/* eslint-disable import/no-unresolved */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter, Route, Routes, useLocation,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import HttpHelpers from './iticket-sdk/services/helpers';
import store from './iticket-sdk/redux/storeConfig';
import localStorageProvider from './localStorageProvider';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-intl-tel-input/dist/main.css';
import HomeLayout from './layouts/home-layout';
import PublicDarkLayout from './layouts/public-dark-layout';
import PublicWhiteLayout from './layouts/public-white-layout';
import ScrollToTop from './routes/scroll-to-top';
import Home from './views/home';
import About from './views/about';
import EventDetails from './views/event/event-details';
import BookingComplete from './views/BookingComplete';
import BookTicket from './views/event/book-ticket';
import NotFound from './views/not-found';
import FAQ from './views/FAQ';
import SellOnTicket from './views/sellOnTicket';
import UpcominEvents from './views/Upcoming-Events';
import MyTickets from './views/myTickets';
import ResetPassword from './views/auth/Reset-Password';
import Settings from './views/settings';
import DynamicPage from './views/dynamicPage/indes';

HttpHelpers.setBaseUrl(process.env.REACT_APP_API_URL);

function App() {
  const { i18n } = useTranslation();
  document.querySelector('html').dir = i18n.dir();

  useEffect(() => {
    localStorageProvider.get('locale').then((lng) => {
      // const locale = 'en';
      let locale;
      if (!lng) {
        locale = 'en';
      } else {
        locale = lng;
      }

      if (!lng) localStorageProvider.set('locale', locale);
      i18n.changeLanguage(locale);
      document.querySelector('html').dir = i18n.dir();
      document.querySelector('html').lang = locale;

      document.querySelector('#bootstrap-ltr').disabled = locale === 'ar';
      document.querySelector('#bootstrap-rtl').disabled = locale === 'en';
    });
  }, []);

  return (
    <Provider store={store}>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<PublicDarkLayout />}>
            <Route
              path="/events/event-details/:eventId"
              element={<EventDetails />}
            />
          </Route>

          <Route element={<PublicWhiteLayout />}>
            <Route path="/upcoming-events" element={<UpcominEvents />} />
          </Route>
          <Route element={<PublicWhiteLayout />}>
            <Route path="/FAQ" element={<FAQ />} />
          </Route>
          <Route element={<PublicWhiteLayout />}>
            <Route path="/sell-on-ticket" element={<SellOnTicket />} />
          </Route>
          {/* ****private route with secret property ***** */}
          <Route element={<PublicWhiteLayout secret />}>
            <Route path="/booking/confirm/:id" element={<BookingComplete />} />
          </Route>
          <Route element={<PublicWhiteLayout basicFooter />}>
            <Route
              path="/events/book-ticket/:eventId"
              element={<BookTicket />}
            />
          </Route>
          <Route element={<PublicWhiteLayout secret basicFooter />}>
            <Route path="/my-tickets/:status" element={<MyTickets />} />
          </Route>
          <Route element={<PublicWhiteLayout secret />}>
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<PublicWhiteLayout secret basicFooter />}>
            <Route path="/settings" element={<Settings />} />
          </Route>

          <Route element={<PublicWhiteLayout />}>
            <Route
              path="/:pageSlug/:pageId"
              element={<DynamicPage />}
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
    </Provider>
  );
}

export default App;

// email: 'cto@iticket.com',
// password: 'Aa01020304$$',
