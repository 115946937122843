import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function VerifyPopup({
  showPopup, setShowPopUp,
}) {
  const { t } = useTranslation();
  const [mailsent, setMailSent] = useState(false);

  const handleClosePopup = useCallback(() => {
    setShowPopUp(false);
    // setMailSent(false);
  }, []);
  return (
    <Modal className="auth-modal mt-4 mt-md-0" isOpen={showPopup} toggle={handleClosePopup} centered>
      <div className="close-icon cursor-pointer" onClick={handleClosePopup}>
        {' '}
        <span className="iticket-icon-close" />
        {' '}
      </div>
      <ModalBody className="px-lg-5 pb-5">
        {mailsent ? (
          <div>
            <span className="iticket-icon-right mb-4" />
            <p className="text-center mb-3 sign-up-text">
              Your email now is verified!
            </p>
          </div>
        ) : (
          <>
            <h3 className="font-weight-semiBold mb-2">Email Verification</h3>
            <p className="text-center mb-3 sign-up-text">We sent you a conformation link to your email address, Please open the link to confirm your account.</p>
            <button onClick={handleClosePopup} type="button" className="d-block w-100 primary main-button">Got it</button>

          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default VerifyPopup;
