import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function UpdateData({
  showUpdatePopup, setShowUpdatePopUp,
}) {
  const { t } = useTranslation();

  const handleClosePopup = useCallback(() => {
    setShowUpdatePopUp(false);
    // setMailSent(false);
  }, []);
  return (
    <Modal className="auth-modal mt-4 mt-md-0" isOpen={showUpdatePopup} toggle={handleClosePopup} centered>
      <div className="close-icon cursor-pointer" onClick={handleClosePopup}>
        {' '}
        <span className="iticket-icon-close" />
        {' '}
      </div>
      <ModalBody className="px-lg-5 pb-5">
        <div>
          <span className="iticket-icon-right mb-4" />
          <p className="text-center mb-3 sign-up-text">
            Data Updated Successfully
          </p>
        </div>

      </ModalBody>
    </Modal>
  );
}

export default UpdateData;
