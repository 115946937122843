import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import ReactCodeInput from 'react-verification-code-input';

const OtpInput = React.forwardRef(({ theme, ...props }, ref) => {
  React.useImperativeHandle(ref, () => ({}));

  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <ReactCodeInput onChange={onChange} value={value} fields={4} fieldWidth={55} fieldHeight={45} className={`otp-input otp-main-input mb-4 ${theme === 'light' ? 'light' : ''}`} />
        )}
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </FormGroup>
  );
});

export default OtpInput;
