/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import appStore from '../../../assets/images/app-store.png';
import googlePlay from '../../../assets/images/google-play.png';
import paymentMethods from '../../../assets/images/payment-methods.png';
import logo from '../../../assets/images/logo.svg';
import { subscribe } from '../../../iticket-sdk/redux/actions/home.actions';
import HomeApiEndpoints from '../../../iticket-sdk/services/home.api';

// const socialIcons = [
//   {
//     id: 1, name: 'Facebook', link: '/', class: 'iticket-icon-facebook',
//   },
//   {
//     id: 2, name: 'Instagram', link: '/', class: 'iticket-icon-insta',
//   },
//   {
//     id: 3, name: 'Twitter', link: '/', class: 'iticket-icon-twitter',
//   },
//   {
//     id: 4, name: 'Youtube', link: '/', class: 'iticket-icon-youtube',
//   },
// ];
function SocialIcons({ socialIcons }) {
  return (
    <ul className="list-unstyled footer-links social">
      {socialIcons?.map((icon) => (
        <li key={icon.id} className="mb-2 mb-lg-3">
          <a href={icon.url} target="_blank" rel="noreferrer">
            <span className="icon">
              <span className={`${icon.title === 'instagram' ? 'iticket-icon-insta' : `iticket-icon-${icon.title}`}`} />
            </span>
            <span className="text">{icon.title}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}

function Footer() {
  const { i18n, t } = useTranslation();
  const emailRef = useRef();
  const [feedBack, setFeedBack] = useState();
  const dispatch = useDispatch();
  // get social accounts
  const { data: socialAccounts } = useQuery(
    ['social-accounts', i18n.language],
    HomeApiEndpoints.getSocialAccounts,
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data.data;
      },
    },
  );

  // get Pages
  const { data: pages } = useQuery(
    ['pages', i18n.language],
    HomeApiEndpoints.getPages,
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data.data;
      },
    },
  );
  const eventsLinks = [
    { id: '1', link: '/', name: 'Browse Events' },
    { id: '2', link: '/', name: 'My Tickets' },
    { id: '3', link: '/', name: 'Upcoming Events' },
    { id: '4', link: '/sell-on-ticket', name: 'Become Organizer ' },
  ];
  const companyinks = [
    { id: '1', link: '/about', name: 'About Us' },
    { id: '2', link: '/', name: ' Support' },
    { id: '3', link: '/FAQ', name: 'Privacy Policy' },
    { id: '4', link: '/FAQ', name: ' Terms & Conditions' },
  ];

  function handleSubscribe() {
    dispatch(subscribe({
      email: emailRef.current.value,
    })).then((res) => {
      setFeedBack(res?.data?.message);
    }).catch((err) => console.log(err));
  }
  return (
    <footer className="">
      <div className="container">
        <div className="footer-top pt-3 pt-lg-5 pb-2 pb-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="row footer-desktop">
                <div className="col-sm-4 my-2">
                  <h6 className="footer-head mb-4">
                    EVENTS
                  </h6>
                  <ul className="list-unstyled footer-links">
                    {eventsLinks.map((el) => (
                      <li key={el.id} className="mb-3">
                        <Link to={el.link}>
                          {el.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-4 my-2">
                  <h6 className="footer-head mb-4">
                    COMPANY
                  </h6>
                  <ul className="list-unstyled footer-links">
                    {pages?.map((el) => (
                      <li key={el.page_id} className="mb-2">
                        <Link to={`${el.slug}/${el.page_id}`}>
                          {el.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-4 my-2">
                  <h6 className="footer-head mb-4">
                    CONNECT
                  </h6>
                  <ul className="list-unstyled footer-links social">
                    <SocialIcons socialIcons={socialAccounts} />
                  </ul>
                </div>
              </div>
              <div className="footer-mobile mb-4">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h6 className="footer-head">
                        EVENTS
                      </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="list-unstyled footer-links">
                        {eventsLinks.map((el) => (
                          <li key={el.id} className="mb-2">
                            <Link to={el.link}>
                              {el.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h6 className="footer-head">
                        COMPANY
                      </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="list-unstyled footer-links">
                        {pages?.map((el) => (
                          <li key={el.page_id} className="mb-2">
                            <Link to={`${el.slug}/${el.page_id}`}>
                              {el.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h6 className="footer-head">
                        CONNECT
                      </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="list-unstyled footer-links social">
                        <SocialIcons socialIcons={socialAccounts} />
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-lg-4 my-2">
              <form className="join-us">
                <h6 className="header text-uppercase mb-3 mb-lg-4">
                  Join our newsletter
                </h6>
                {feedBack ? <h5 className="feedback mb-3 mb-lg-4">{feedBack}</h5> : (
                  <div className="input-container mb-4">
                    <input className="w-100 p-3" type="email" ref={emailRef} name="email" placeholder="ENTER YOUR EMAIL" />
                    <button type="button" onClick={handleSubscribe} className="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4297 5.93005L20.4997 12.0001L14.4297 18.0701" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.5 12H20.33" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                )}

                <div className="mob-apps">
                  <p className="title mb-2 font-weight-light">
                    Download our app
                  </p>
                  <div className="d-flex">
                    <a href="/"><img src={appStore} alt="appStore" /></a>
                    <a href="/"><img src={googlePlay} alt="google-play" /></a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-bottom py-3 mt-21 mt-md-4">
          <div className="row">
            <div className="col-md-6 my-2 d-flex justify-content-center justify-content-md-start">
              <div className="d-flex flex-column flex-md-row align-items-center">
                <Link to="/">
                  <img src={logo} alt="Iticket-logo" className="footer-logo my-1" />
                </Link>
                <p className="copywrite mb-0 font-weight-light cl-light my-1">
                  © 2021 iticket. All rights reserved.
                </p>
              </div>
            </div>
            <div className="col-md-6 my-2 d-flex justify-content-center justify-content-md-end">
              <img src={paymentMethods} width="235px" className="paymentMethods" alt="paymentMethods" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
