import HttpHelpers from './helpers';

const EventsApiEndpoints = {
  getEvents: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('events', { params })
      .then((response) => response.data);
  },
  // ?paginate=${pageParam}
  eventDetails: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`events/${id}`)
      .then((response) => response.data);
  },
};

export default EventsApiEndpoints;
