/* eslint-disable no-unreachable */
/* eslint-disable default-param-last */
import StorageService from '../../storage-service';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHECK_AUTH':
      if (action.error) return state;
      return {
        ...state,
        authenticated: action.data,
      };
    case 'LOGIN':
      StorageService.set('token', action.data.data.token);
      StorageService.set('auth', true);
      if (action.error) return state;
      return {
        ...state,
        authenticated: true,
      };
    case 'SIGN_UP':
      StorageService.set('token', action.data.data.token);
      StorageService.set('auth', true);
      if (action.error) return state;
      return {
        ...state,
        authenticated: true,
      };

    case 'LOGOUT':
      return {
        ...state,
        authenticated: false,
      };

    default:
      return state;
  }
};

export default authReducer;
