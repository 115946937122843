/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';
import {
  Label, Form, Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import schema from './schema';
import InputField from '../../../components/input';
import { setServerErrors } from '../../../helpers';
import { easyLogin } from '../../../iticket-sdk/redux/actions/auth.actions';
import Loading from '../../../components/Loading';
import { createBooking } from '../../../iticket-sdk/redux/actions/booking.actions';

function EasyLogin({ setCurrentStep, bookingData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    setLoading(true);
    const { userAgent } = window.navigator;
    dispatch(easyLogin(data)).then((res) => {
      if(bookingData){
        dispatch(createBooking(bookingData)).then((res) => {
          console.log(res);
          window.scrollTo(0, 0);
            setCurrentStep(2);         
          setLoading(false);
        }).catch((err) => {
          console.log(err);
          setLoading(false);
        });
      }else{
        setLoading(false);
        setCurrentStep(2);
      }
    }).catch((error) => {
      setLoading(false);
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else if (error?.response?.data?.code === 417) {
        const responseErrors = {
          email: {
            field: 'email',
            message: error?.response?.data?.data,
          },
          phone: {
            field: 'phone',
            message: error?.response?.data?.data,
          },
        };
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  };
  return (
    <Form className=" mt-4" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}

      <Row>
        <Col className="position-relative my-2" md={6}>
          <div className="input-container">
            <label htmlFor="phone_number" className="text-uppercase font-weight-semiBold mb-2">Email Address</label>
            <InputField
              id="phone_number"
              placeholder="Enter your email"
              name="email"
              errors={errors}
              type="email"
              {...register('email')}
            />
          </div>
        </Col>
        <Col className="position-relative my-2" md={6}>
          <div className="input-container">
            <label htmlFor="phone_number" className="text-uppercase font-weight-semiBold mb-2">Mobile Number</label>
            <InputField
              id="phone_number"
              placeholder="+966 xXX XXX XXXX"
              name="phone"
              errors={errors}
              type="number"
              {...register('phone')}
            />
          </div>
        </Col>
        <Col md={6}>
          <button type="submit" className="main-button primary btn d-block w-100">
            {' '}
            payment
            {' '}
            <span className="iticket-icon-arrow-down arrow-right d-inline-block  mx-2" />
          </button>
        </Col>
      </Row>
    </Form>
  );
}

export default EasyLogin;
