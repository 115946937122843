/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const subscribe = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'SUBSCRIBE_REQUEST',
    });
    return Api.home.subscribe(data).then((response) => {
      dispatch({
        type: 'SUBSCRIBE_SUCCESS',
        data: response,
      });
      return response;
    });
  };
};
