import Api from '../../services';
import StorageService from '../../storage-service';
import { getUser } from './user.actions';

export const login = (data) => {
  return (dispatch) => {
    return Api.auth.login(data).then((response) => {
      dispatch({
        type: 'LOGIN',
        data: response,
      });
      dispatch(getUser());
      return response;
    });
  };
};
export const easyLogin = (data) => {
  return (dispatch) => {
    return Api.auth.easyLogin(data).then((response) => {
      dispatch({
        type: 'LOGIN',
        data: response,
      });
      dispatch(getUser());
      return response;
    });
  };
};

export const signUp = (data) => {
  return (dispatch) => {
    return Api.auth.signUp(data).then((response) => {
      dispatch({
        type: 'SIGN_UP',
        data: response,
      });
      return response;
    });
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    const token = await StorageService.get('token');
    dispatch({ type: 'CHECK_AUTH', data: token !== null });
    return token !== null;
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT' });
    return true;
  };
};

export const checkCompleteProfile = () => {
  return async (dispatch) => {
    const missingKeys = await StorageService.get('missing_keys');
    dispatch({ type: 'CHECK_COMPLETE_PROFILE', data: missingKeys });
    return missingKeys;
  };
};

export const changePassword = (data) => {
  return (dispatch) => {
    return Api.auth.changePassword(data).then((response) => {
      dispatch({
        type: 'Change_PASSWORD',
        data: response,
      });
      return response;
    });
  };
};
export const forgetPassword = (data) => {
  return (dispatch) => {
    return Api.auth.forgetPassword(data).then((response) => {
      dispatch({
        type: 'FORGET_PASSWORD',
        data: response,
      });
      return response;
    });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    return Api.auth.resetPassword(data).then((response) => {
      dispatch({
        type: 'RESET_PASSWORD',
        data: response,
      });
      return response;
    });
  };
};
export const confirmEmail = (id) => {
  return (dispatch) => {
    return Api.auth.confirmEmail(id).then((response) => {
      dispatch({
        type: 'CONFIRM-EMAIL',
        data: response,
      });
      return response;
    });
  };
};
