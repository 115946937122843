import React, { useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { InputGroup, InputGroupText } from 'reactstrap';
import location from '../../assets/images/icons/map-pin.svg';

function MultiDatepicker({ values, setValues, handleAfterClose }) {
  return (
    <InputGroup className="input-group-merge input-group-calender">
      <InputGroupText>
        <span className="iticket-icon-calendar" />
      </InputGroupText>
      <DatePicker
        value={values}
        onChange={setValues}
        onClose={handleAfterClose}
        range
        numberOfMonths={2}
        showOtherDays
        calendarPosition="top-bottom"
        placeholder="Filter by Date"
        shouldCloseOnSelect={false}
        inline={false}
        rangeHover
        style={{
          background: '#FFFFFF',
          border: '2px solid #E8E9F3',
          borderRadius: '10px',
          padding: ' 13px',
          height: ' 50px',
        }}
      />
    </InputGroup>
  );
}

export default MultiDatepicker;
