import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup
  .object({
    name: yup.string().required(() => i18n.t('auth.validations.required')),
    // last_name: yup.string().required(() => i18n.t('auth.validations.required')),
    phone: yup.string().required(() => i18n.t('auth.validations.required')),
    email: yup.string().email(() => i18n.t('auth.validations.emailFormat')).required(() => i18n.t('auth.validations.required')),
    password: yup.string().required('Password field is required')
      .min(8, 'Password must contain 8 letters/numbers minimum')
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password should have at least one capital letter, one number, one special character and doesn’t allow spaces.',
      ),
    password_confirmation: yup.string().required(() => i18n.t('auth.validations.required'))
      .oneOf([yup.ref('password')], () => i18n.t('auth.validations.passwordMatch')),
  })
  .required();

export default schema;
