import React, { useState, useEffect, useCallback } from 'react';
import {
  Label, Form, Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import schema from './schema';
import InputField from '../../../components/input';
import { setServerErrors } from '../../../helpers';
import { signUp } from '../../../iticket-sdk/redux/actions/auth.actions';
import { getUser } from '../../../iticket-sdk/redux/actions/user.actions';
import Loading from '../../../components/Loading';

function SignUp({
  isModalOpen, toggleModal, openSignInModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    const { userAgent } = window.navigator;
    const newData = { ...data, device_name: userAgent, method: 'email' };
    dispatch(signUp(data)).then((res) => {
      dispatch(getUser()).then((response) => {
        localStorage.setItem('userID', response.data.id);
      });
      toggleModal();
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error?.response?.data?.code === 422) {
        const responseErrors = error.response.data.data.errors;
        setServerErrors(responseErrors, setError);
      } else {
        toast.error('Something went wrong');
      }
    });
  };
  const hanleOpenLogin = useCallback(() => {
    toggleModal();
    openSignInModal(true);
  }, [openSignInModal]);
  return (
    <Modal className="auth-modal mt-4 mt-md-0" isOpen={isModalOpen} toggle={() => toggleModal()} centered>
      <div className="close-icon cursor-pointer" onClick={() => toggleModal()}>
        {' '}
        <span className="iticket-icon-close" />
        {' '}
      </div>
      <ModalBody className="px-lg-5">
        <h3 className="font-weight-semiBold mb-2">Sign up on iticket Now!</h3>
        <p className="text-center mb-3 sign-up-text">You can sign up using</p>
        <div className="buttons-container d-flex justify-content-between py-2 px-3 px-md-5">
          <button type="button" className="btn d-flex align-items-center">
            {' '}
            <span className="iticket-icon-google px-2" />
            {' '}
            Google
          </button>
          <button type="button" className="btn d-flex align-items-center">
            <span className="iticket-icon-apple px-2" />
            {' '}
            Apple
          </button>
        </div>
        <p className="text-center py-3 sign-up-text">Or using your email</p>

        <Form className=" mt-3 px-3 px-md-5" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                placeholder="Name"
                name="name"
                errors={errors}
                type="name"
                {...register('name')}
              />
            </Col>
            <Col md={12}>
              <InputField
                placeholder="Enter your email"
                name="email"
                errors={errors}
                type="email"
                {...register('email')}
              />
            </Col>
            <Col md={12}>
              <InputField
                placeholder="Phone number"
                name="phone_number"
                errors={errors}
                type="phone_number"
                {...register('phone')}
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder="password"
                name="password"
                errors={errors}
                type={showPass ? 'text' : 'password'}
                {...register('password')}
                wrapperClassName="ltr-input pass-input"
              />
              <span onClick={() => setShowPass(!showPass)} className="cursor-pointer show-pass iticket-icon-eye" />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder="Re-enter Password"
                name="password_confirmation"
                errors={errors}
                type={showConfirmPass ? 'text' : 'password'}
                {...register('password_confirmation')}
                wrapperClassName="ltr-input pass-input"
              />
              <span onClick={() => setShowConfirmPass(!showConfirmPass)} className="cursor-pointer show-pass iticket-icon-eye" />
            </Col>
            <Col md={12}>
              <button type="submit" className="main-button primary btn d-block w-100"> Sign Up</button>
            </Col>
          </Row>
        </Form>
        <p className="mt-4 text-center cursor-pointer sign-up-text">
          Already have an account?
          {' '}
          <span onClick={hanleOpenLogin} className="main-color cursor-pointer"> Login</span>
        </p>
      </ModalBody>
      {loading
      && <Loading /> }
    </Modal>
  );
}

export default SignUp;
