import React from 'react';
import {
  Col, Container, Row, InputGroupText, InputGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import img1 from '../../assets/images/card.png';
import img2 from '../../assets/images/card2.png';
import img3 from '../../assets/images/card3.png';
import img4 from '../../assets/images/card4.png';

function UpcominEvents() {
  const eventsArr = [
    {
      img: img1,
      date: '29 October',
      title: 'David Lee Garza with Ramon Ayala',
      location: 'Space, riyadh',
      price: '375 SR',
      category: 'concert',
    },
    {
      img: img2,
      date: '29 Novmber',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '400 SR',
      category: 'comedy',
    },
    {
      img: img4,
      date: '29 October',
      title: 'David Lee Garza with Ramon Ayala',
      location: 'Space, riyadh',
      price: '350 SR',
      category: 'concert',
    },
    {
      img: img3,
      date: '20 Novmber',
      title: 'David Lee Garza ',
      location: 'Space, riyadh',
      price: '150 SR',
      category: 'art',
    },
    {
      img: img4,
      date: '20 February',
      title: 'David Lee Garza ',
      location: 'Space, riyadh',
      price: '240 SR',
      category: 'science',
    },
    {
      img: img2,
      date: '05 October',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '500 SR',
      category: 'design',
    },
    {
      img: img1,
      date: '15 October',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '50 SR',
      category: 'acting',
    },
    {
      img: img4,
      date: '15 October',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '150 SR',
      category: 'dance',
    },
    {
      img: img4,
      date: '20 February',
      title: 'David Lee Garza ',
      location: 'Space, riyadh',
      price: '240 SR',
      category: 'science',
    },
    {
      img: img2,
      date: '05 October',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '500 SR',
      category: 'design',
    },
    {
      img: img1,
      date: '20 February',
      title: 'David Lee Garza ',
      location: 'Space, riyadh',
      price: '240 SR',
      category: 'conference',
    },
    {
      img: img4,
      date: '05 October',
      title: 'Manuel De La Mare jam',
      location: 'Space, riyadh',
      price: '500 SR',
      category: 'conference',
    },
  ];
  return (
    <div className="padding-top previous-main-events-container">
      <Container>
        <div className="mt-4">
          <Link className="main-button secondary lg text-uppercase mb-4" to="/">
            <span className="mx-2">
              <span className="iticket-icon-arrow-down arrow-left d-inline-block" />
            </span>
            <span className="mx-2">
              Go home
            </span>
          </Link>
          {/* BreadCrumb */}
          <div className="breadcrumb d-flex align-items-center">
            <Link to="/">
              Home
            </Link>
            <span className="icon mx-2 mx-lg-3">
              <span className="iticket-icon-arrow-down arrow-right d-inline-block" />
            </span>
            <Link to="/">
              Upcoming Events
            </Link>
          </div>
          <div className="d-flex justify-content-between align-content-lg-center mb-4 mt-2 flex-column flex-lg-row">
            <h3 className="title text-uppercase font-weight-semiBold pb-1 mb-3">
              Upcoming Events
            </h3>
            <InputGroup className="banner-search-input">
              <InputGroupText>
                <span className="iticket-icon-search" />
              </InputGroupText>
              <input className="px-2" placeholder="Search events..." type="search" />
            </InputGroup>
          </div>
        </div>
        <Row className="mb-3 mb-lg-5 mt-2 mt-lg-4 mt-lg-2">
          {eventsArr?.map((card) => (
            <Col lg="4" md="6" sm="6" className="my-3 my-lg-4">
              <Card card={card} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default UpcominEvents;
