/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const eventDetails = (id) => {
  return (dispatch) => {
    return Api.events.eventDetails(id).then((response) => {
      dispatch({
        type: 'GET_BOOKING_DATA',
        data: response,
      });
      return response;
    });
  };
};
