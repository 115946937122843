import * as helpers from './helpers';
import AuthApiEndpoints from './auth.api';
import HomeApiEndpoints from './home.api';
import EventsApiEndpoints from './events.api';
import UserApiEndpoints from './user.api';
import BookingApiEndpoints from './booking.api';

const Api = {
  ...helpers,
  auth: AuthApiEndpoints,
  user: UserApiEndpoints,
  home: HomeApiEndpoints,
  events: EventsApiEndpoints,
  booking: BookingApiEndpoints,
};

export default Api;
