/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { Form } from 'react-bootstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import sellOnImg from '../../assets/images/sell-on.png';
import InputField from '../../components/input';
import TelephoneInput from '../../components/phone-input';
import schema from './schema';
import UpdateData from './updateData';

function SellOnTicket() {
  const [showUpdatePopup, setShowUpdatePopUp] = useState(false);

  const {
    reset, register, handleSubmit, control, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    console.log(data);
    setShowUpdatePopUp(true);
    reset({
      name: '', companyName: '', eventNum: '', phone_number: '', email: '',
    });
  };

  return (
    <div className="sell-on-ticket_page">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7 col-sm-9 col-11">
            {/* Head */}
            <div className="header text-center">
              <h2 className="title font-weight-semiBold">
                Become organizer and sell on iTicket
              </h2>
              <p className="subtitle mt-3 mb-4 mb-md-5">
                Urna eu sed nunc amet quam consequat sapien commodo. Sem morbi a lobortis id lorem interdum rhoncus faucibus.
              </p>
            </div>
            {/* */}
            <img src={sellOnImg} className="img-fluid main-img d-flex m-auto" alt="" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">

            {/* Form */}
            <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="company_name" className="text-uppercase font-weight-semiBold mb-2">Company name</label>
                <InputField
                  placeholder="Your company..."
                  name="companyName"
                  errors={errors}
                  type="text"
                  {...register('companyName')}
                />
              </div>

              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="personal_name" className="text-uppercase font-weight-semiBold mb-2">Personal Name </label>
                <InputField
                  placeholder="Your name..."
                  name="name"
                  errors={errors}
                  type="text"
                  {...register('name')}
                />
              </div>

              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="phone_number" className="text-uppercase font-weight-semiBold mb-2">
                  Phone Number
                </label>
                <TelephoneInput
                  name="Phone Number"
                  control={control}
                  errors={errors}
                  {...register('phone_number')}
                  theme="light"
                  wrapperClassName="mb-1 w-100"
                />
              </div>

              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="email" className="text-uppercase font-weight-semiBold mb-2">Email</label>
                <InputField
                  placeholder="Email"
                  name="email"
                  errors={errors}
                  type="email"
                  {...register('email')}
                />
                {' '}

              </div>

              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="events_number" className="text-uppercase font-weight-semiBold mb-2">
                  how many events in year?
                </label>
                <InputField
                  placeholder="Example 45 events..."
                  name="name"
                  errors={errors}
                  type="text"
                  {...register('eventNum')}
                />
              </div>

              <div className="input-container mb-3 mb-md-4">
                <label htmlFor="activity_type" className="text-uppercase font-weight-semiBold mb-2">
                  Activity Type
                </label>
                <Form.Select>
                  <option selected>event</option>
                  <option>ceremony</option>
                  <option>Default select</option>
                  <option>Default select</option>
                </Form.Select>
              </div>

              <div className="row mt-4 pt-3">
                <div className="col-6">
                  <button type="button" className="main-button secondary lg text-uppercase w-100">Cancel</button>
                </div>
                <div className="col-6">
                  <button type="submit" className="main-button primary lg text-uppercase w-100">Apply</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <UpdateData showUpdatePopup={showUpdatePopup} setShowUpdatePopUp={setShowUpdatePopUp} />

    </div>
  );
}

export default SellOnTicket;
