import HttpHelpers from './helpers';

const BookingApiEndpoints = {
  createBooking: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('booking', data)
      .then((response) => response.data);
  },
  saveBooking: (data) => {
    return HttpHelpers.authenticatedAxios
      .post("booking/payment/save", data)
      .then((response) => response.data);
  },
};

export default BookingApiEndpoints;
