import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup
  .object({
    current_password: yup.string().required('Password field is required'),
    new_password: yup.string().required('Password field is required')
      .min(8, 'Password must contain 8 letters/numbers minimum')
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password should have at least one capital letter, one number, one special character and doesn’t allow spaces.',
      ),
    new_password_confirmation: yup.string().required(() => i18n.t('auth.validations.required'))
      .oneOf([yup.ref('new_password')], () => i18n.t('auth.validations.passwordMatch')),
  })
  .required();

export default schema;
