import i18n from 'i18next';

const selectStyles = {
  container: (styles) => ({
    ...styles,
    flex: 1,
  }),
  control: (base) => ({
    ...base,
    border: '2px solid #E8E9F3',
    borderTopLeftRadius: i18n.language === 'ar' ? '10px' : '0',
    borderBottomLeftRadius: i18n.language === 'ar' ? '10px' : '0',
    borderTopRightRadius: i18n.language === 'ar' ? '0' : '10px',
    borderBottomRightRadius: i18n.language === 'ar' ? '0' : '10px',
    borderLeft: i18n.language === 'ar' ? '2px solid #E8E9F3 ' : 'none',
    borderRight: i18n.language === 'ar' ? 'none' : '2px solid #E8E9F3',
    boxShadow: 'none',
    backgroundColor: '#f9f9fc',
    width: '177px',
    flex: 1,
    '&:hover': {
      borderColor: '#e8e9f3',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#191919',
    backgroundColor: state.isSelected ? '#eee' : '#fff',
    border: 'none',
    padding: '12px 10px',
    fontSize: '14px',
    width: '177px',
    '&:hover': {
      backgroundColor: '#e2e2e2',
      color: '#191919',
      cursor: 'pointer',
      borderColor: '#ccc',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#191919',
  }),
  IndicatorsContainer: (base) => ({
    ...base,
    padding: '0',
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    backgroundColor: '#f9f9fc',
    borderRadius: 0,
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '165px',
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 42,
    padding: 0,
  }),
  input: (base) => ({
    ...base,
    height: 42,
    margin: 0,
    padding: 0,
  }),
};

export default selectStyles;
