import HttpHelpers from './helpers';

const UserApiEndpoints = {
  getUser: (data) => {
    return HttpHelpers.authenticatedAxios
      .get('user/profile', data)
      .then((response) => response.data);
  },

  updateUserProfile: (data, params) => {
    return HttpHelpers.authenticatedAxios
      .post('user/profile/update/information', data, { params })
      .then((response) => response.data);
  },
  getLanguage: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('language', { params })
      .then((response) => response.data);
  },
  updateLanguage: (data, params) => {
    return HttpHelpers.authenticatedAxios
      .post('user/language/update', data, { params })
      .then((response) => response.data);
  },

  profileVerifications: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/profile/verify', data)
      .then((response) => response.data);
  },
  verifyPhoneNumber: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('user/profile/verify/otp', data)
      .then((response) => response.data);
  },

};

export default UserApiEndpoints;
