/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo, useState } from 'react';
import {
  withGoogleMap, GoogleMap, withScriptjs, Marker,
} from 'react-google-maps';

import Geocode from 'react-geocode';

const MAP_KEY = 'AIzaSyAGx0qvu2VZJisbvjQbhhZQ0hAv7Ut2T1I';
Geocode.setApiKey("AIzaSyA_y8dQkjHXKKA-V_NXluTZ96JEpkRgijQ ");
Geocode.enableDebug();

function MapComponent({ lat, lng }) {

  const AsyncMap = withScriptjs(
    withGoogleMap(
      (props) => (
        <GoogleMap
          defaultZoom={15}
          defaultCenter={{ lat: lat, lng: lng }}
        >
          {/* Marker */}
          <Marker
            google={props.google}
            name="Dolores park"
            // draggable={true}
            // onDragEnd={onMarkerDragEnd}
            position={{ lat: lat, lng: lng }}
          />
          <Marker />
        </GoogleMap>
      ),
    ),
  );

  return (
    <>
      {/* {lat && lng && (
        )} */}
      <AsyncMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA_y8dQkjHXKKA-V_NXluTZ96JEpkRgijQ&libraries=places"

        loadingElement={
          <div style={{ height: '100%' }} />
        }
        containerElement={
          <div className="map-container" style={{ height: '260px', position: 'relative' }} />
        }
        mapElement={
          <div style={{ height: '100%', borderRadius: '14px' }} />
        }
      />
    </>
  );
}

export default memo(MapComponent);
