import React from 'react';
import barcode from '../../assets/images/barcode.svg';
import tick from '../../assets/images/tick.jpg';
import wallet from '../../assets/images/wallet.svg';

function TicketCard() {
  return (
    <div className="ticket-card-container-parent mb-4">
      <div className="ticket-card-container">
        <div className="text-center img-barcode mb-4">
          <img src={barcode} alt="QR code" className="barcode pb-3" />
        </div>
        <div className="border-dashed my-4" />
        <div className="tick-img-container mb-3">
          <img src={tick} alt="QR code" className="tick-img" />
        </div>
        <span className="">CONCERT</span>
        <h2 className="title mt-3">
          DJ David Tort saturday night live JAM
        </h2>
        <h5 className="mt-3">LOCATION</h5>
        <h6>Space Middle East, 52 king fahd st, riyadh</h6>
        <div className="d-flex justify-content-between">
          <div className="">
            <h5 className="mt-3">DATE</h5>
            <h6> 15.11.2021 </h6>
          </div>
          <div className="">
            <h5 className="mt-3"> TIME </h5>
            <h6> 07:00 PM </h6>
          </div>
        </div>
      </div>
      <div className="text-center card-summery">
        <p>
          Ticket ID:
          {' '}
          <span className="main-color">5014584ARG</span>
        </p>
        <div className="d-flex buttons-container justify-content-between">
          <button className="btn button-wallet w-100 p-0" type="button"> View </button>
          <button className="btn main-button primary border-0 w-100 p-0" type="button"> Download </button>
        </div>
        <button type="button" className="button-wallet d-flex btn mt-3 font-weight-semiBold m-auto px-4 align-items-center">
          <img src={wallet} alt="wallet" />
          {' '}
          wallet
        </button>
      </div>
    </div>
  );
}

export default TicketCard;
