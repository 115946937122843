import React from 'react';
import { Link } from 'react-router-dom';

import notFoundImg from '../assets/images/404.svg';

function NotFound() {
  return (
    <div className="not-found_page d-flex flex-column justify-content-center align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="content text-center">
              <img src={notFoundImg} alt="404" className="img-fluid" />
              <h1 className="title font-weight-semiBold mt-4 mb-3 mt-md-5 mb-md-4">
                Oh No! Page Not Found.
              </h1>
              <p className="text px-0 px-md-5">
                The page you’re looking for does not exict. You can go homepage by clicking the button below.
              </p>
              <Link to="/" className="main-button primary lg text-uppercase my-2 my-md-3 px-4 px-md-5">
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
