import React from 'react';
import { Container } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Autoplay, Pagination, EffectFade,
} from 'swiper';
import TicketCard from '../../components/TicketCard';

function BookingComplete() {
  const params = {
    slidesPerView: 4,
    spaceBetween: 10,
    watchOverflow: 'true',
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1.4,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1.4,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 1.8,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2.9,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  };
  return (
    <div className="confirm-booking padding-top mt-4">
      <Container>
        <h5 className="text-center py-lg-5 py-3">
          Thanks ahmed!
          <br />
          Your booking has been completed.
        </h5>
        <div className="tickets-slider">
          <h4 className="font-weight-semiBold">
            Your Tickets
          </h4>
          <div className="confirm-tickets-container py-3">
            <div className="swiper-container">
              <div className="w-100 swiper tickets-swiper-container">
                <Swiper className="w-100" {...params}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((image) => (
                    <SwiperSlide className="slider-slide">
                      <TicketCard />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiper-button-next"><span className="iticket-icon-arrow-left-large arrow-left" /></div>
                <div className="swiper-button-prev">
                  {' '}
                  <span className="iticket-icon-arrow-left-large arrow-right" />
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BookingComplete;
