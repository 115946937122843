import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup
  .object({
    name: yup.string().required(() => i18n.t('auth.validations.required')),
    // last_name: yup.string().required(() => i18n.t('auth.validations.required')),
    phone: yup.string().required(() => i18n.t('auth.validations.required')),
    email: yup.string().email(() => i18n.t('auth.validations.emailFormat')).required(() => i18n.t('auth.validations.required')),

  })
  .required();

export default schema;
