import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/images/event-card.png';
import location from '../../assets/images/location.svg';

export default function EventCard() {
  return (
    <Link to="/events/event-details/:2">
      <div className="event-card-top-container">
        <div className="event-card-container">
          <img className="main-img" src={img} alt="" />
        </div>
        <div className="content">
          <p>29 October 2021</p>
          <h3 className="text-white">LUCIEN FOORT </h3>
          <div className="d-flex text-center justify-content-center">
            <span className="iticket-icon-location1-path1 iticket-icon-location1-path2" />
            <img src={location} alt="location-img" />
            <span className="px-2">Space, riyadh</span>
          </div>
        </div>
      </div>
    </Link>

  );
}
